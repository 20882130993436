/* 
	Section Background (bg.css)
	
	Table of Content
	- general settings for section & subheader
	- general background image for subheader
	- background settings for page subheader
	- background settings for section
	- subheader for side navigation
	- media query
*/

/* general settings for section & subheader */
section,
#subheader {
	-webkit-background-size: 100% !important;
	-moz-background-size: 100% !important;
	-o-background-size: 100% !important;
	background-size: 100% !important;
}
 

 

#view-all-projects,
#call-to-action {
	padding: 60px 0 60px 0;
}

 
 

#section-about-us-3 {
	background: #f5f5f5;
	height: 100%;
}

#section-about-us-3 .image-container {
	background: url(../images/background/bg-side-2.jpg);
}
 
  

#section-describe {
	background: #202020;
}

 
 

#section-about-us-2 {
	background: #18191b;
	height: 100%;
}

.de_light #section-about-us-2 {
	background: none;
}
  
#section-portfolio {
	background: #222;
	width: 101%;
	overflow: hidden;
}

.de_light #section-portfolio {
	background: #fff;
}
 

#section-steps-2 {
	background: #f5f5f5;
}

 
#section-team {
	background: #111;
}

.de_light #section-team {
	background: #f5f5f5;
}

#hide-content {
	position: absolute;
	width: 100%;
	min-height: 100%;
	z-index: 100;
	display: none;
	background: rgba(0, 0, 0, .8);
}
     
#section-service-2,
#section-service-4 {
	background: #f2f2f2;
}


/* background settings for side navigation subheader */
 

.bg-fit-height {
	-webkit-background-size: auto 100% !important;
	-moz-background-size: auto 100% !important;
	-o-background-size: auto 100% !important;
	background-size: auto 100% !important;
	background-attachment: scroll !important;
}

/* media query */
@media only screen and (max-width: 767px) {

	section,
	#subheader {
		-webkit-background-size: auto 100% !important;
		-moz-background-size: auto 100% !important;
		-o-background-size: auto 100% !important;
		background-size: auto 100% !important;
		background-attachment: scroll !important;
		background-position: top !important;
	}
}