html {
overflow-x:hidden;
padding: 0 !important;
background:#fff;
margin:0px !important;
}

#revolution-slider{
font-family:"Open Sans";
}

.tp-caption{
font-size:14px;
font-weight:300;
}

.tp-caption.teaser{
letter-spacing:5px;
}

.tp-caption br{
margin-bottom:5px;
}

.tparrows{ top:50% !important;}

.med{
font-size:24px;
letter-spacing:-.5px;
}


.med-white{
color:#fff;
font-size:24px;
letter-spacing:-.5px;
}
.med-green{
color:#1a8b49;
font-size:24px;
letter-spacing:-.5px;
}
.small-white{
color:#fff;
line-height:1.7em;
}

.big-black{
font-size:52px;
letter-spacing:-3px;
color:#222;
}

.big-white{
font-family:"Dosis";
font-size:15px;
color:#fff;
line-height:normal;
font-weight:300;
text-transform:uppercase;
letter-spacing:10px;
}

.ultra-big-white{
font-family:"Dosis";
font-size:72px;
font-weight:300;
letter-spacing:15px;
color:#fff;
margin:0;
text-transform:uppercase;
}

.ultra-big-black{
font-size:96px;
font-weight:400;
color:#222;
margin:0;
line-height:72px;
}

a.btn-slider{
color:#fff;
}

a.btn-slider:hover{
color:#222;
}

a.btn-custom,
a.btn-custom:hover{
	color:#000000;
	font-weight:500;
	font-size:12px;
	padding:10px 20px;
}

.fullwidthbanner-container a.btn{
text-transform:uppercase;
letter-spacing:1px;
color:#fff !important;
font-weight:700;
padding:10px 20px 10px 20px;
border-radius:0;
-moz-border-radius:0;
-webkit-border-radius:0;
}

.tp-caption.separator {
width:100px;
border-bottom:solid 1px rgba(255,255,255,.3);
}


.white{
	color:#ffffff;
}