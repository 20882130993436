.pricing-s1.light .top {
    background: #222427;
}
.pricing-s1.light .top .price .month {
    color: #ccc;
}
.pricing-s1.light .top .price b {
    font-size: 48px;
    line-height: 70px;
    font-weight: 500;
    color: #fff;
}
.pricing-s1.light .bottom ul li:nth-child(odd) {
    background: #2f3135;
    border:none;
}

.pricing-s1.light .bottom ul li:nth-child(even) {
    background: #2a2c30;
    border:none;
}
.pricing-s1.light .bottom {
    background: #222427;
}