h6{
  text-align: justify;
  font-size:14px;
}
@media (min-width: 992px){
  .container {
  /*  max-width: 1063px; */
  }
}
/* Make the image fully responsive */
.carousel-inner img {
width: 100%;
height: 80%;
}
.carousel-caption {
position: absolute;
right: 15%;
bottom:306px;
left: -39%;
z-index: 10;
padding-top: 20px;
padding-bottom: 20px;
color: #fff;
text-align: center;
}
.carousel-caption h3 {
margin-top: 0;
font-size: 2rem;
letter-spacing: 3px;
text-transform: uppercase;
margin-bottom: 15px;
}
.carousel-caption p{
font-size: 3rem;
font-weight:100;
}

.welcome-message .borderBtn {
  position: absolute;
  top: 300%;
  left: 45%;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.05em;
  color: white;
  padding: 1.45em 3.35em;
  border-radius: 0.25em;
  background: #fab702;
}

.borderBtn:hover {
  color: #fab702;
  background-color: #363b3f;
}

.container .btn-lg {
  background: #fab702;
  border: 0.05em solid#FAB702;
  color: #292727;
  padding: 10px 20px;
  margin: 40px;
  font-size:14px;
}

.container .btn-lg:hover {
  background: white;
  border: 0.05em solid #fab702;
  color: #fab702;
}

.modal-body .form-control {
  border-radius: 0.35em;
  margin-right: 0.25em;
  padding: 0.15em;
}

.modal-body .form-group label {
  margin-left: 0.35em;
}

.modal-header {
  background: #fab702;
  padding: 2px;
}

.modal-body .form-group {
  text-align: left;
}

.close {
  color: white !important;
  opacity: 0.8 !important;
}

.mohe {
  position: relative;
  display: block;
  color: white;
  float: left;
  padding: 0.75em;
}

/*.mohe:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  top: -1.45em;*/
/*  bottom: -1.45em;*/
/*  right: -7.15em;*/
/*  border-left: 0.05em solid rgba(0, 0, 0, 0.2);*/
/*  border-right: 0.05em solid rgba(255, 255, 255, 0.7);*/
/*  transform: rotate(25deg);*/
/*}*/
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  color: #5f5b5b;
}
.modal-footer {
  background: #fab702;
  padding: 0px;
}

.modal-footer button {
  background: white;
  color: black;
  width: 9em;
  border-radius: 0.35em;
}

.input-group textarea {
  height: 6.35em !important;
  background-color: rgba(255, 255, 255, 0.9);
}

.form-control {
  /* height: 2em;
  padding: 0.75em 1.15em;
  font-size: 1.15em;
  border: 0.05em solid black !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: black; */
}

.input-group .btn-primary {
  color: rgba(255, 255, 255, 1);
  background-color: black !important;
  width: 50% !important;
  float: right !important;
  margin-right: 0 !important;
}

.input-group .btn-primary:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  color: #363b3f;
  border: 0.15em solid black;
  border-radius: 0.35em;
  font-weight: 700;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width:350px;
    margin: 1.75rem auto;
  }
}

body {
  display: block !important;
}





#subheader {
  padding-bottom:230px;
}

.subheader-bg-color{
  background-color: #222;
}

.tab_steps .wow.fadeIn .v-border{
  opacity: 0;
}
.tab_steps .wow.fadeIn.active .v-border{
  opacity: 1;
}




.title {
  font-size: 60px;
  padding: 20px 0px;
}

.container {
  /* width: 900px; */
  margin: 0px auto;
}
/* Float three columns side by side */
.column {
  float: left;
  width: 33.33%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 700px) {
  .container {
    width: 100%;
  }

  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: center;
  background-color: #2f3135;
  border-radius: 10px;
  cursor: pointer;
}
.card .profile {
  border-radius: 10px;
  transition: 0.2s;
}
.card:hover .profile {
  transform: scale(1.4);
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

.card .content {
  width: 100%;
  /* height: 100%; */
  position: relative;
  overflow: hidden;
}
.card .content .back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1e6;
  transition: 1s;

  z-index: 1;
  padding: 10px;
}

.from-left {
  top: 0;
  left: -100%;
}
.card:hover .content .from-left {
  left: 0%;
}

.from-bottom {
  top: 100%;
  left: 0;
}
.card:hover .content .from-bottom {
  top: 0%;
}

.from-right {
  top: 0%;
  right: -100%;
}
.card:hover .content .from-right {
  right: 0;
}

.card .content .back h3 {
  font-size: 15px;
  letter-spacing: 2px;
}
.card .content .back .tem-img {
  border-radius: 100%;
}

.card .content .back .des {
  font-size: 13px;
  padding: 20px 0px;
}
.card .content .back .social-icon {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.card .content .back .social-icon li {
  display: inline-block;
}

.card .content .back .social-icon li a {
  display: block;
  background: #333;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 100%;
}
.back h2,.back h3,.back h4{
  color:#333;

}
.des{
  color:#333;
}
.cursor-pointer{
  cursor: pointer;
}

.hideCourse{
  display: none;
}

/*carousel-indicators*/

.carousel-indicators{
  bottom: -20px;
}

.carousel-indicators button{
  width: 5px;
  height: 7px;
  margin: 5px 3px;
  background: #D6D6D6;
  border-radius: 30px;
  border: none;
  display: block;
}
 
.carousel-indicators button.active{   
  background: #FAB702; 
}
#contact_form .text-danger{
  display: block;
  margin-top: -14px;
}
.icon i:before {
  font-family: 'et-line';
}


/*popup video*/
.popup-video-1{
  top: 0px; position: absolute; height: 620px;
}


.bottom-0{
  top: 5px;
}
.end-0{
right: 0;
}