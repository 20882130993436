

@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
@import url(https://fonts.googleapis.com/css?family=Dosis:400,200,300,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Allura);


html{
background:#000000 !important;
}

body {
	display:none;
	font-family: "Open Sans",Arial, Helvetica, sans-serif;
	font-size: 13px;
	font-weight:400;
	color: #dddddd;
	line-height: 1.8em;
	padding: 0;
	background:#606060;
}

body.de_light,.de_light{
	color:#606060;
}

.rtl,
.rtl .tp-caption{
	text-align:right;
}
.rtl header div#logo{
	float:right;
}
.rtl header nav {
	float:left;
}
.rtl #subheader h1{
	float:right;
	padding-right: 0px;
	margin-right: 0px;
}
.rtl .crumb {
	float:left;
	padding:0;
	margin:0;
}
.rtl .de-team-list .small-border{
	float:right;
}
.rtl .crumb li{
	float:right;
	padding:0 5px;
}

#wrapper{
overflow:hidden;
}

.de-navbar-left #wrapper{
overflow:visible;
}

/* ================================================== */
/*  header	*/ 
/* ================================================== */
	header {
	width: 100%;
	/*height: 80px;*/
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	-webkit-transition: height 0.3s;
	-moz-transition: height 0.3s;
	-ms-transition: height 0.3s;
	-o-transition: height 0.3s;
	transition: height 0.3s;
	background:#18191b;
	border-bottom:solid 1px rgba(0,0,0,0.0);
	}
	
	header .info{
	display:none;
	color:#fff;
	text-align:right;
	padding:10px 0 10px 0;
	width:100%;
	z-index:100;
	border-bottom:solid 1px rgba(255,255,255,.2);
	}
	header .info .column{
	display:inline-block;
	padding:0 15px 0 15px;
	font-size:11px;
	letter-spacing:1px;
	}
	header .info .social.column{
	border:none;
	}
	header .info .social i{
	color:#fff;
	font-size:14px;
	display:inline-block;
	float:none;
	padding:0 10px 0 10px;
	}
	header .info strong{
	font-size:12px;
	}
	
	header.header-light .info{
	color:#333;
	}
	header.header-light .info .social i{
	color:#333;
	}
	header.transparent.header-light .info,
	header.transparent.header-light .info .social i{
	color:#fff;
	}
	
	header.header-bg{
	background:rgba(0,0,0,0.5);
	}
	
	/*header.transparent{*/
	/*background:rgba(0,0,0,0.0);*/
	/*}*/
	
	header.autoshow{
	top:-80px;
	}	
	
	header.autoshow.scrollOn,header.autoshow.scrollOff{
	-o-transition:.5s;
	-ms-transition:.5s;
	-moz-transition:.5s;
	-webkit-transition:.5s;
	transition:.5s;
	outline: none;	
	}
	
	header.autoshow.scrollOn{
	top:0;
	}
	
	header.autoshow.scrollOff{
	top:-80px;
	}
	
	header div#logo {
	display: table;
	height: 80px;
	float: left;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s; 
	}
	header div#logo .logo-2{
	display:none;
	}
	header div#logo a{
	display:table-cell;
    vertical-align:middle;
	}
	header nav {
	float:right;
	}
	header nav {
		display: flex;
		align-items: center;
		height: 100%;
	}
	header nav a {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s; 
	}
	header nav a:hover {
	color: #555; 
	}
	header.smaller:not(.header-bottom):not(.side-header) #mainmenu a{
	padding-top: 18px;
	padding-bottom:18px;
	}
	header.smaller:not(.header-bottom):not(.side-header) #mainmenu li li a{
	padding-top: 0px;
	padding-bottom: 0px;
	}
	header.smaller:not(.header-bottom):not(.side-header) #mainmenu ul{
	top:70px;
	}
	header.smaller:not(.header-bottom):not(.side-header) #mainmenu ul ul{
	top:0px;
	}
	header.smaller:not(.header-bottom):not(.side-header){
	/*height: 70px;*/
	background:#18191b;
	}
	header.smaller.no-border:not(.header-bottom):not(.side-header){
	border:none;
	}
	header.smaller:not(.header-bottom):not(.side-header) div#logo {
	height:70px;
	}
	
	.logo-smaller div#logo {
	width: 150px;
	height:30px;
	line-height: 65px;
	font-size: 30px;
	}
	.logo-smaller div#logo img{
	line-height: 75px;
	font-size: 30px;
	}
	.logo-smaller  div#logo .logo-1{
	display:none;
	}
	.logo-smaller  div#logo .logo-2{
		display:inline-block;
	}
	.logo-small{
	margin-bottom:20px;
	}
		
	header.de_header_2{
		height:125px;	
	}
	
	header.de_header_2.smaller {
		height:70px;
	}
	
	header.de_header_2.smaller .info{
		position:absolute;
		opacity:0;
		z-index:-1;
	}
	
	header.header-light{
	background:#fff;
	}
	
	header.header-light.transparent{
	background:none;
	}
	
	header.smaller:not(.header-bottom).header-light{
	background:#fff;
	border-bottom-color:#eee;
	}
	
	.de-navbar-left{
	background:#151618 ;
	}
	
	.de-navbar-left #logo{
	padding:40px;
	text-align:center;
	width:100%;
	}
	
	.de-navbar-left header{
	position:relative;
	display:block;
	width:100%;
	height:100%;
	left:0;
	background:none;
	padding:30px;
	padding-right:20px;
	}
	
	
	.de-navbar-left header #mainmenu{
	float:none;
	margin:0;
	padding:0;
	}	
	
	.de-navbar-left header #mainmenu > li{
	display:block !important;
	margin:0;
	float:none;
	text-align:center;
	font-family:"Dosis";
	letter-spacing:5px;
	font-size:14px;
	padding:0;
	padding-top:10px;
	padding-bottom:10px;
	border-bottom:solid 1px rgba(255,255,255,.1);
	}	
	.de_light.de-navbar-left header #mainmenu > li{
	border-bottom:solid 1px rgba(0,0,0,.05);
	}
	.de-navbar-left header #mainmenu > li > a{
	padding:0;
	opacity:.5;
	}	
	.de_light.de-navbar-left header #mainmenu > li > a{
	color:#222;
	}	
	.de-navbar-left header #mainmenu > li a:hover{
	opacity:1;
	}
	.de-navbar-left header #mainmenu > li >a:after{
	content:none;
	}
	.de-navbar-left header nav{
	float:none !important;
	}
	
	
	/* subheader */
	#subheader{
	padding-bottom:70px;
	background:#222;
	}
	.de-navbar-left  #subheader{
	padding-bottom:120px;
	}
	#subheader h1 {
	color:#eceff3;
	margin-top:80px;
	font-size: 32px;
	letter-spacing: 5px;
	float: left;
	padding-right: 40px;
	margin-right: 40px;
	text-transform:uppercase;
	}
	#subheader span {
	letter-spacing:2px;
	display: inline-block;
	font-size: 15px;
	margin-top:88px;
	color:#fff;
	}
	#subheader .crumb{
	font-size:10px;
	letter-spacing:3px;
	margin-top: 90px;
	color:#fff;
	text-transform:uppercase;
	}
	.de-navbar-left.de_light #subheader h1{
	color:#222;
	}
	
	#subheader.style-2 .title-wrap{
	float:left;
	margin-top:60px;
	}
	#subheader.style-2{
	padding:150px 0 150px 0;
	}
	#subheader.style-2 h1{
	font-size:56px;
	text-align:left;
	margin:0;
	float:none;
	font-weight:bold;
	letter-spacing:1px;
	}
	#subheader.style-2 h4{
	color:#fff;
	margin-top:10px;
	font-weight:500;
	text-transform:none;
	}
	

/* ================================================== */
/* navigation */
/* ================================================== */
	.header-custom:not(.header-mobile){
	height:auto;
	position:relative;
	width:100%;
	left:0;
	}
	.header-custom:not(.header-mobile) nav{
	display:block;
	width:100%;
	text-align:center;
	margin-bottom:-1px;
	float:none;
	}
	.header-custom:not(.header-mobile).s2 nav{
	text-align:left;
	background:none;
	margin:0;
	padding:0;
	float:left;
	}	
	.header-custom:not(.header-mobile) nav #mainmenu{
	display:inline-block !important;
	margin:0;
	float:none;
	padding:0;
	}
	.header-custom:not(.header-mobile) #mainmenu > li > a{
	padding: 20px 0px 20px 20px;
	line-height:20px;
	}
	.header-custom:not(.header-mobile) #mainmenu > li:first-child > a{
	padding-left:0;
	}
	.header-custom:not(.header-mobile) div#logo{
	width:100%;
	}
	.header-custom:not(.header-mobile) div#logo img{
	text-align:center;
	}
	.header-custom:not(.header-mobile) #mainmenu li > ul,
	.header-custom:not(.header-mobile) #mainmenu li:hover > ul{
	margin-top:-20px;
	}
	
	.header-custom:not(.header-mobile) #mainmenu li ul ul,
	.header-custom:not(.header-mobile) #mainmenu li:hover ul ul {
	top:20px;
	}
	.header-custom:not(.header-mobile) #mainmenu li ul.mega,
	.header-custom:not(.header-mobile) #mainmenu li:hover ul.mega{
	margin-top:60px;
	}
	.header-custom:not(.header-mobile) .menu-group{
	background:rgba(255,255,255,.02);
	margin-bottom:-1px;
	height:60px;
	padding-top:-1
	}
	.header-custom:not(.header-mobile) .form-default{
		margin-top:-5px;
	}
	.header-custom.header-light:not(.header-mobile) .menu-group{
	background:rgba(0,0,0,.05);
	}
	
	#mainmenu {
	font-family:"Open Sans", Arial, Helvetica, sans-serif;
	float: right;
	font-size:11px;
	font-weight:400;
	letter-spacing:3px;
	text-transform:uppercase;
	margin-bottom: 0px;
	}	
	
	#mainmenu ul {
	float: right;
	margin: 0px 0px;
	padding: 0px 0px;
	height: 30px;
	}
	
	#mainmenu li {
	margin: 0px 0px;
	padding: 0px 0px;
	float: left;
	display: inline;
	list-style: none;
	position: relative;
	}
	
	#mainmenu a{
	line-height:30px;
	}
		
	#mainmenu > li > a{
	line-height:35px;
	}
		
	#mainmenu a {
	display: inline-block;
	padding: 25px 0px 25px 20px;
	text-decoration: none;
	text-align:center;
	outline:none;
	}
	
	#mainmenu a,
	header.transparent.header-light #mainmenu a{
	color:#fff;
	}
	
	#mainmenu li a:after { float:right; position:relative; font-family:"FontAwesome"; content:"\f111"; font-size:6px; margin-left:20px; margin-top:0px; color:#FAB702; }
	
	#mainmenu li:last-child a:after{display:none;}
	
	#mainmenu.no-separator li a:after{
	font-size:0px;
	}	
	
	#mainmenu.line-separator li a:after{
	content:"/";
	font-size:14px;
	}	
	
	#mainmenu.circle-separator li a:after{
	content:"\f10c";
	font-size:9px;
	}	
	
	#mainmenu.strip-separator li a:after{
	content:"\f068";
	font-size:9px;
	}	
	
	#mainmenu.plus-separator li a:after{
	content:"\f067";
	font-size:11px;
	}	
	
	#mainmenu.square-separator li a:after{
	content:"\f096";
	font-size:11px;
	}		
	
	
	#mainmenu li li {
	font-size: 10px;
	font-weight: normal; 
	border-bottom: solid 1px #333;
	}
	
	#mainmenu li li:last-child {
	border-bottom: none;
	}
	

	#mainmenu li li a {
	padding: 0px 12px 0px 12px;
	background: #222327;
	border-top: none;
	color: #ccc;
	width:201px;
	border-left:none;
	text-align:left;
	}
	
	#mainmenu li li a:hover{
	color:#111;
	}
	
	#mainmenu li li a:after {
	content:none !important;
	}
	
	#mainmenu li li li a {
	background: #222327;
	}
	
	#mainmenu li li a:hover {
	color:#111;
	background: #1a8b49;
	}
	
	#mainmenu li ul {
	width: 201px;
	height: auto;
	position: absolute;
	top: 80px;
	left: 0px;
	z-index: 10;
	visibility:hidden;
	opacity:0;
	margin-top:5px;
	}
	
	#mainmenu li li {
	display: block;
	float: none;
	}
	
	#mainmenu li:hover > ul {
	visibility: visible;
	opacity:1;
	margin-top:0px;
	}
	
	#mainmenu ul li:hover > a{
	color:#222;
	}
	
	header.header-mobile #mainmenu li ul {
	visibility: visible;
	opacity:1;
	margin-top:0px;
	}
	
	#mainmenu li ul ul {
	left: 100%;
	top: 0px;
	}
	
	#mainmenu li:hover > ul {
	color: #1a8b49;
	}
	
	#mainmenu select {
	padding: 10px;
	height: 36px;
	font-size: 14px;
	border: none;
	background: #ff4200;
	color:#eceff3;
	}
	
	#mainmenu select option {
	padding: 10px;
	}
	
	
	header.header-light #mainmenu > li > a,
	header.transparent.smaller.header-light #mainmenu > li > a{
	color:#333;
	}
	
	#mainmenu > li ul.mega{
	position:fixed;
	left:0;
	height:0%;
	width:100%;
	visibility: hidden;
	opacity: 0;
	overflow:hidden;
	padding-left:40px;
	padding-right:40px;
	}
	#mainmenu li:hover ul.mega{
	visibility: visible;
	opacity: 1;
	}
	#mainmenu li ul.mega > li,
	#mainmenu li ul.mega > li a{
		width:100%;
	}
	#mainmenu li ul.mega li.title{
		padding:0px 10px 10px 15px;
		text-align:left;
		color:#fff;
		font-weight:bold;
	}
	#mainmenu li ul.mega > li ul{
		position:static;
		visibility: visible;
		opacity: 1;
		left:0;
		float:none;		
		width:100%;
	}
	#mainmenu ul.mega > li ul li{
		margin:0;
		padding:0;
	}
	
	#mainmenu ul.mega > li ul li a{
		margin:0;
	}
	
	#mainmenu ul.mega > li ul li:last-child{
		border-bottom:solid 1px #333333;
	}

	#mainmenu li ul.mega .menu-content{
		background:#222327;
		padding:30px;
	}
	header.header-mobile #mainmenu > li ul.mega{
	visibility: visible;
	opacity: 1;
}
	header:not(.header-mobile) #mainmenu > li:hover ul.mega{
		height:auto;
	}
	/* menu toggle for mobile */
	#menu-btn{
	display:none;
	float:right;
	margin-top:5px;
	width:36px;
	height:36px;
	padding:6px;
	text-align:center;
	cursor:pointer;
	}
	
	.header-light #menu-btn{
	background:none;
	}
	
	#menu-btn:before{
	font-family:FontAwesome;
	content: "\f0c9";
	font-size:24px;
	color:#eceff3;
	}
	
	.header-light #menu-btn:before{
	color:#333;
	}
	
	#menu-btn.unclick:before{
	content: "\f0c9";
	}
	
	#menu-btn.clicked:before{
	content: "\f00d";
	}
	
	#menu-btn:hover{
	background:#fff;
	}
	
	#menu-btn:hover:before{
	color:#222;
	}
	
	/* menu toggle animated */
	
	#nav-toggle {
	float:right;
	  width: 60px;
	  height: 20px;
	  position: relative;
	  margin: 15px auto;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .5s ease-in-out;
	  -moz-transition: .5s ease-in-out;
	  -o-transition: .5s ease-in-out;
	  transition: .5s ease-in-out;
	  cursor: pointer;
	  padding:10px;
	  z-index:100;
	}

	#nav-toggle span{
	  display: block;
	  position: absolute;
	  height: 2px;
	  width: 50%;
	  background: #fff;
	  border-radius: 9px;
	  opacity: 1;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .25s ease-in-out;
	  -moz-transition: .25s ease-in-out;
	  -o-transition: .25s ease-in-out;
	  transition: .25s ease-in-out;
	}

	#nav-toggle span:nth-child(1) {
	  top: 10px;
	}

	#nav-toggle span:nth-child(2) {
	  top: 18px;
	}

	#nav-toggle span:nth-child(3) {
	  top: 26px;
	}

	#nav-toggle.open span:nth-child(1) {
	  top: 18px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}

	#nav-toggle.open span:nth-child(2) {
	  opacity: 0;
	}

	#nav-toggle.open span:nth-child(3) {
	  top: 18px;
	  -webkit-transform: rotate(-135deg);
	  -moz-transform: rotate(-135deg);
	  -o-transform: rotate(-135deg);
	  transform: rotate(-135deg);
	}
	
	#menu-wrapper{
	width:100%;
	height:100%;
	background:rgba(0,0,0,.95);
	position:fixed;
	top:0;
	left:0;	
	text-align:center;
	height:0;
	overflow-y:auto;
	}
	
	#menu-wrapper  nav{
	display:inline-block;
	margin:20px auto 80px auto;
	padding:0;
	float:none;
	font-size:26px;
	opacity:0;
	}


/* ================================================== */
/* content */
/* ================================================== */
	#content {
	width:100%;
	background:#fff;
	padding:90px 0 90px 0;
	z-index:100;
	}
	
	.de_light #content{
	background:#fff;
	}
		
	
	#content{
	background:#18191b;
	}


/* ================================================== */
/* section */
/* ================================================== */
	section{padding:90px 0 90px 0;}
	section{background:#ffffff;}
	
	.de-navbar-left .container-fluid .container{
	width:100% !important;
	}
	
	.de-navbar-left section{
	padding:70px;
	}
	
	.de_light section{
	background:#fff;
	}
	
	.bg-white{
	background:#ffffff;
	}
	
	.section-fixed {
	position:fixed;
	width:100%;
	min-height:100%;
	height:auto;
	}
	
/* ================================================== */
/* footer */
/* ================================================== */
footer {
	background: #111;
	color: #ccc;
	padding: 80px 0 0 0;
	}
	
	footer h3, .de_light footer h3 {
	color:#fff;
	font-size: 20px;
	}
	
	footer a {
	color: #ccc;
	text-decoration:none !important;
	}
	
	footer a:visited {
	color: #ccc;
	}
	
	footer.light{
		color:#606060;
		background:#fff;
	}
	
	footer.light .subfooter{
	background:#f8f8f8;
	}
	
	footer.light a{
		color:#606060 !important;
		text-decoration:none;
	}

	.de-navbar-left footer{
	padding:70px 70px 0 70px;
	}
	
	.subfooter{
	background:#0b0b0b;
	padding:30px 0 20px 0;
	}
	
	.subfooter.light{
		border-top:solid 1px #ddd;
		background:#fff;
	}
	
	.de-navbar-left footer.light .subfooter{
	background:none;
	}
	
	.de-navbar-left .subfooter{
	background:none;
	}
	
	header.side-header{
	border-bottom:none !important;
	margin:0;
	}
	
	header.side-header .social-icons-2{
	margin:0 auto;
	margin-top:50px !important;
	float:none;
	text-align:center;
	}
	
	header.side-header .social-icons-2 a{
	display:inline-block;
	margin:0 2px 0 2px;
	background:#555;
	width:36px;
	height:36px;
	padding-top:5px;
	display:inline-block;
	text-align:center;
	border-radius:20px;
	-moz-border-radius:20px;
	-webkit-border-radius:20px;
	opacity:.2;	
	}
	
	.de_light header.side-header .social-icons-2 a{
	background:#fff;
	color:#333;
	}
	
	header.side-header .social-icons-2 a:hover{
	opacity:1;
	}	
	
	.de-navbar-left.de_light header, .de-navbar-left.de_light{background:#f9f9f9;}

	header.header-mobile{
	background:#111;
	}
	
	header.header-mobile.header-light{
	background:#fff;
	}
	
	header.smaller.header-scroll:not(.autoshow){
	position:absolute;
	}
	
	.header-bottom .info{
	display:none !important;
	}
	
	#de-sidebar{
	position:absolute;
	}
	

/* ================================================== */
/* blog */
/* ================================================== */
	.blog-list {
	margin: 0;
	padding: 0;
	}
	/* blog list */
	.blog-list li {
	list-style: none;
	line-height: 1.7em;
	margin-bottom: 60px;
	margin-top:10px;
	padding-bottom:15px;
	border-bottom:solid 1px rgba(255,255,255,.1);
	}
	.de_light .blog-list li{
	border-bottom:solid 1px #ddd;
	}
	.blog-list h3,
	.blog-read h3{
	margin-top:20px;
	}	
	.blog-list h3 a{
	color:#fff;
	}
	.de_light  .blog-list h3 a{
	color:#111;
	}
	.de_light  .text-light  .blog-list h3 a{
	color:#fff;
	}
	.blog-list .btn-more{
	padding:3px 25px 3px 25px;
	color:#111;
	text-transform:uppercase;
	text-decoration:none;
	font-weight:700;
	float:right;
	}
	.blog-list .btn-more:hover{
	background:#fff;
	}
	.de_light .blog-list .btn-more:hover{
	background:#222;
	color:#fff;
	}
	.blog-list .post-text,
	.blog-read .post-text{
	padding-left:80px;
	}
	.blog-list img{
	border-bottom:solid 4px;
	}
	.blog-list .blog-slider{
	margin-bottom:-60px;
	}
	.blog-list .date-box,
	.blog-read .date-box{
	width: 60px;
	position: absolute;
	text-align: center;
	text-shadow:none;
	}
	.blog-list .date-box .day, .blog-list .date-box .month {
	display: block;
	color:#fff;
	text-align:center;
	width:60px;
	z-index:100;
	}
	
	.de_light .blog-list .date-box .day, .de_light .blog-read .date-box .month {
	color:#111;
	}
	
		
	.blog-list .date-box .month {
	font-size:14px;
	}
	
	
	.blog-list .date-box .day,
	.blog-read .date-box .day{
	color:#111;
	font-weight:600;
	font-size: 32px;
	padding-top:45px;
	padding-bottom:10px;
	}
	
	.blog-list .date-box .month,
	.blog-read .date-box .month 	{
	color:#fff;
	font-family:"Open Sans";
	font-weight:600;
	letter-spacing:16px;
	padding:7px 0 7px 0;
	border-bottom:solid 4px #333;
	}

	.de_light .blog-list .date-box .month,
	.de_light .blog-read .date-box .month	{
	border-bottom-color:#ddd;
	}
	
	.de_light  .text-light  .blog-list .date-box .month{
	color:#fff;
	}
	
	.blog-snippet li{
	border-bottom:none !important;
	margin-bottom:0;
	}
	
	#blog-carousel .item{
	width:100%;
	}
	
	/* blog comment */
	#blog-comment {
	margin-left: 80px;
	}
	
	#blog-comment h5 {
	margin-bottom: 10px;
	}
	
	#blog-comment ul, #blog-comment li {
	list-style: none;
	margin-left: -30px;
	padding-left: 0;
	}
	
	#blog-comment ol{
	padding-left:30px;
	}
	
	#blog-comment li {
	min-height: 70px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: solid 1px rgba(255,255,255,.1);
	}
	
	#blog-comment li .avatar {
	position: absolute;
	margin-top: 5px;
	}
	
	#blog-comment .comment {
	margin-left: 90px;
	}
	
	#blog-comment li {
	min-height: 70px;
	font-size: 12px;
	line-height: 1.6em;
	}
	
	#blog-comment li li {
	margin-left: 55px;
	padding-bottom: 0px;
	margin-top: 20px;
	padding-top:20px;
	border-top: solid 1px rgba(255,255,255,.1);
	border-bottom: none;
	}
	
	#blog-comment li li .avatar {
	position: absolute;
	}
	
	#blog-comment li li .comment {
	}
	
	.comment-info {
	margin-left: 90px;
	margin-bottom: 5px;
	}
	
	.comment-info span {
	}
	
	.comment-info .c_name {
	display: block;
	font-weight: 700;
	}
	
	.comment-info .c_reply {
	padding-left: 20px;
	margin-left: 20px;
	border-left: solid 1px rgba(255,255,255,.1);
	font-size: 12px;
	}
	
	.comment-info .c_date {
	font-size: 12px;
	}
	
	.blog-list h3, .blog-read h3 {
	letter-spacing:normal;
	font-size:24px;
	text-transform:none;
	}

	/* blog comment form */
	#commentform label {
	display: block;
	}
	#commentform input {
	width: 290px;
	}
	#commentform input:focus {
	border: solid 1px #999;
	background: #fff;
	}
	#commentform textarea {
	width: 97%;
	padding: 5px;
	height: 150px;
	color:#333;
	}
	#commentform textarea:focus {
	border: solid 1px #999;
	background: #fff;
	}
	#commentform input.btn {
	width: auto;
	}
	
	.post-meta {
	margin: 0px 0 10px 0px;
	border: solid 1px #eee;
	border-left:none;
	font-size:11px;
	display:table;
	table-layout: fixed;
	margin-left:80px;
	}
	.post-meta span {
		display:table-cell;
		padding:10px 20px 10px 20px;
		text-align:center;
		border-left:solid 1px #eee;
	}
	.post-meta span i {
	float: none;
	margin-right:20px;
	}
	.post-image img {
	width:100%;
	margin-bottom: 0px;
	}
	.post-meta{
	border-color:#222;
	}
	.post-meta span{
	border-color:#222;
	}
	
	.de_light .post-meta {
	border: solid 1px #eee;
	}
	.de_light .post-meta span {
	border-left:solid 1px #eee;
	}
	


	
	.blog-slide{
	padding:0;
	margin:0;
	}

/* ================================================== */
/* products */
/* ================================================== */

.products{
padding:0;
list-style:none;
}
.products li{
margin-bottom:40px;
}
.product .price{
margin-bottom:10px;
font-size:16px;
}
.product h4{
font-weight:500;
}
.product img{
background:#333;
border:solid 1px #fff;
margin-bottom:10px;
border-radius:5px;
-moz-border-radius:5px;
-webkit-border-radius:5px;
}
.product .btn-line{
	width:100%;
}
.de_light .product img{
padding:0;
border:solid 1px #ddd;
}	
	
/* ================================================== */
/* contact form */
/* ================================================== */
	.error {
	padding:20px;
	border:none;
	/* display: none; */
	color: #d9534f;
	border:solid 1px #d9534f;
	font-size: 12px;
	}
	.success {
	padding:20px;
	/* display: none; */
	color: #5cb85c;
	border:solid 1px #5cb85c;
	margin-bottom: 20px;
	}
	.error img {
	vertical-align: top;
	}
	.full {
	width: 98%;
	}

	#contact_form{
	margin-top:20px;
	}
	
	#contact_form input[type=text],#contact_form textarea,#contact_form input[type=email],#contact_form input[type=number],#search{
	padding:10px;
	margin-bottom:20px;
	color:#fff;
	border:solid 1px rgba(0,0,0,.3);
	background:rgba(0,0,0,.2);
	border-radius:0 !important;
	height:auto;
	}

	.de_light #contact_form input[type=text],.de_light #contact_form textarea,.de_light #contact_form input[type=email], .de_light #search
		{
		border:solid 1px #ddd;
		background:#f5f5f5;	
		color:#333 !important;
		}

	
	
	
	#contact_form input[placeholder],#contact_form textarea[placeholder]{
	color:#eee;
	}
	#contact_form input[type=submit]{
	background:none;
	}
	
	#contact_form input[type=submit]:hover{
	color:#fff !important;
	background:#111;
	border-color:#111;
	}
	
	#contact_form textarea{
	height:165px;
	}
	
	.form-default input[type=text]{
	background:#1D1E1F;
	border:none;
	font-size:14px;
	padding:2px 10px 2px 10px;
	color:#ffffff;
	}
	
	.header-light .form-default input[type=text]{
	background:#f2f2f2;
	color:#333333;
	}
	
	.pop-search .form-default{
	width:100%;
	top:5px;
	left:0;
	position:absolute;
	z-index:1;
	display:none;
	}
	
	.pop-search .form-default input[type=text]{
	width:100%;
	font-size:24px;
	padding:13px;
	outline:none;
	}
	.pop-search-click{
	display:inline-block;
	position:absolute;
	top:20px;
	cursor:pointer;
	z-index:100;
	}
	.pop-search-click:before{
	margin-left:-15px;
	font-family:"FontAwesome";
	content:"\f002";
	}
	.pop-search-click.click:before{
	content:"\f00d";
	}

	
	.error_input{
	border-color:#D31E2B !important;
	}

/* ================================================== */
/* coming soon page */
/* ================================================== */
	
	.coming-soon .logo{
	margin:0;
	padding:0;
	}
	
	.coming-soon h2{
	margin-top:0;
	color:rgba(255,255,255,.8);
	font-weight:400;
	}
	
	.coming-soon  .social-icons i{
	font-size:20px;
	margin-left:10px;
	margin-right:10px;
	}
	
	.coming-soon .social-icons i:hover{
	background:none;
	color:rgba(255,255,255,.2) !important;
	}
	
	
	.arrow-up, .arrow-down {
	width: 0; 
	height: 0; 
	border-left: 40px solid transparent;
	border-right: 40px solid transparent;
	border-bottom: 40px solid #fff;
	position:fixed;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	bottom:0;
	cursor:pointer;
	z-index:101;
	}
	
	.arrow-down {
	border-top: 40px solid #fff;
	border-bottom:none;
	position:fixed;
	z-index:102;
	top:-40px;
	}
	
	.arrow-up:before{
	font-family:"FontAwesome";
	font-size:20px;
	content:"\f067";
	color:#222;
	position:relative;
	top:12px;
	margin:0 0 0 -8px !important;
	}
	
	.arrow-down:before{
	font-family:"FontAwesome";
	font-size:20px;
	content:"\f068";
	color:#222;
	position:relative;
	top:-35px;
	margin:0 0 0 -8px !important;
	}
	

/* ================================================== */
/* elements */
/* ================================================== */
	
	/* address */
	/* ------------------------------ */
	address{
	margin:0;
	margin-top:20px;
	}
	address span {
	display: block;
	border-bottom:solid 1px #222;
	padding-bottom:5px;
	margin-bottom:5px;
	font-weight:300;
	}
	
	footer.light address span {
	border-bottom:solid 1px #eee;
	}
	
	.de_light .widget_text address span {
	border-bottom:solid 1px #ddd;
	}
	
	address span strong {
        display: inline-block;
        width: 60px;
    }
	
	address span{
	border-bottom-color:#222;
	}
	
	address span i{
	margin-top:5px;
	margin-right:20px;
	float:none;
	}
	
	address span strong {
	display: inline-block;
	}
	
	address span a{
	color:#eceff3;
	}
	
	.de_light #sidebar .widget address span{
	border-bottom:solid 1px #ddd;
	}
	
	.de_light #sidebar .widget address a{
	color:#333;
	}
	
	
	/* border */
	/* ------------------------------ */
	.small-border{
	height:1px;
	width:120px;
	background:#ccc;
	display:block;
	margin:0 auto;
	margin-top:20px;
	margin-bottom:30px;
	}
	
	/* breadcrumb */
	/* ------------------------------ */
	.crumb {
	color:#777;
	float: right;
	font-size: 12px;
	}
	.de-navbar-left.de_light #subheader .crumb{
	color:#333;
	}
	.crumb li {
	display: inline;
	text-decoration: none;
	padding: 0 2px 0 2px;
	}
	
	.crumb a{
	text-decoration:none;
	}
	
	.de_light .crumb a:hover{
	color:#fff;
	}

	
	/* button */
	/* ------------------------------ */
	.btn-line, a.btn-line{
	border:solid 1px #222;
	color:#fff;
	font-weight:500;
	font-size:12px;
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	padding:3px;
	padding-left:30px;
	padding-right:50px;
	text-transform:uppercase;
	display:inline-block;
	text-align:center;
	letter-spacing:2px;
	text-decoration:none;
	
	}

	.de_light a.btn-line{
		color:#111;
		border:solid 1px #dddddd;
	}

	.btn-line, a.btn-line,
	.text-light a.btn-line{
	border:solid 1px rgba(255,255,255,.2);
	color:#fff;
	}
	

	.btn-line:hover, a.btn-line:hover{
	background:#fff;
	color:#111;
	border-color:#fff;
	}
	
	a.btn-line:after{
		position:absolute;
		padding-left:0px;
		margin-top:1px;
	}
	
	a.btn-line:hover:after{
		position:absolute;
		padding-left:10px;
	}
	
	.btn-line:after,  a.btn-line:after{
	color:#fff;
	}
	
	.btn-line:hover:after, a.btn-line:hover:after, a.btn-line.hover:after{
	color:#fff;
	}
	
	.btn-line:hover:after, a.btn-line:hover:after, a.btn-line.hover:after{
	color:#111;
	}
	
	a.btn-text{
	text-decoration:none;
	display:inline-block;
	color:#111;
	font-weight:600;
	padding:0;
	}
	
	a.btn-text:after{
	font-family:FontAwesome;
	content: "\f054";
	padding-left:10px;
	}
	
	a.btn-text{
	color:#fff;
	}
	
	a.btn-big{
	font-size:14px;
	color:#eceff3;
	letter-spacing:1px;
	line-height:normal;
	font-weight:bold;
	text-transform:uppercase;
	border:solid 1px #fff;
	padding:10px 50px 10px 30px;
	}
	a.btn-big:after{
	font-family:FontAwesome;
	content: "\f054";
	margin-left:20px;
	margin-top:3px;
	}
	
	.de_light a.btn-big{
	font-weight:normal;
	}
	
	.btn-line, a.btn-line{
	color:#eceff3;
	}
	
	.btn-line:after, a.btn-line:after{
	font-family:FontAwesome;
	content: "\f054";
	margin-left:10px;
	}
	
	a.btn-line-black, .de_light a.btn-line-black{
	border:solid 1px #111;
	color:#111;
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	font-weight:normal;
	}
	
	a.btn-line-black:hover{
	background:#111;
	color:#fff;
	}
	a.btn-line.black,
	a.btn-line.black:hover{
	border-color:#111111;
	color:#111111;
	}
	a.btn-line.black:hover{
	color:#ffffff;
	background:#111111;
	}
	a.btn-line.black:after,
	.de_light a.btn-line.black:after{
	color:#111111;	
	}
	a.btn-line.black:hover:after{
	color:#ffffff;
	}
	
	.de_light a.btn-line-black:hover{
	background:#111;
	color:#fff;
	}
	
	.btn-fullwidth{
	width:100%;
	}
	
	a.btn-slider{
	display:inline-block;
	font-size:13px;
	font-weight:600;
	color:#fff;
	letter-spacing:3px;
	line-height:normal;
	text-decoration:none;
	text-transform:uppercase;
	border:solid 1px rgba(255,255,255,.5);
	padding:10px 30px 10px 30px;
	}
	a.btn-slider.dark{
	color:#333;
	border:solid 1px rgba(0,0,0,.5);	
	}
	a.btn-slider:after{
	font-family:FontAwesome;
	content: "\f054";
	margin-left:10px;
	}
	a.btn-slider:hover{
	color:#222;
	background:#fff;
	border-color:#000;
	border:solid 2px #fff;
	}
	a.btn-slider.dark:hover{
	color:#fff;
	background:#333;
	border:solid 1px rgba(0,0,0,.5);	
	}
	
	a.btn-custom{
	font-size:13px;
	color:#222;
	letter-spacing:3px;
	line-height:normal;
	text-decoration:none;
	text-transform:uppercase;
	padding:7px 25px 7px 25px;
	background:#fff;
	display:inline-block;
	}
	a.btn-custom:hover{
	color:#222;
	opacity:.8;
	}
	
	/* columns */
	/* ------------------------------ */

	
	/* counter */
	/* ------------------------------ */
	.de_count{
	text-align:center;
	padding:30px 0 30px 0;
	background:none;
	}
	.de_count h3{
	font-family:"Dosis", Arial, Helvetica, sans-serif;
	font-size:40px;
	}
	.de_light .de_count h3{
	font-family:"Poppins";
	font-weight:500;
	}
	.de_count span{
	text-transform:uppercase;
	}
	.de_count i{
		display:block;
		font-size:60px;
		margin:0 auto;
		margin-bottom:20px;
		width:100%;
		font-weight:lighter !important;
	}
		
	/* divider */
	/* ------------------------------ */
	.spacer-single{
	width:100%;
	height:30px;
	display:block;
	clear:both;
	}
	
	.spacer-double{
	width:100%;
	height:60px;
	display:block;
	clear:both;
	}
	
	.spacer-half{
	width:100%;
	height:15px;
	display:block;
	clear:both;
	}
	
	hr {
	display: block;
	clear: both;
	border-top: solid 1px #ddd;
	margin: 40px 0 40px 0;
	}


	
	/* dropcap */
	/* ------------------------------ */
	.dropcap {
	display: inline-block;
	font-size: 48px;
	float: left;
	margin: 10px 15px 15px 0;
	color:#eceff3;
	padding: 20px 10px 20px 10px;
	}
	
	
	/* form style */
	/* ------------------------------ */


	.de_form input[type="radio"], 
	.de_form input[type="checkbox"] {
	/* hide the inputs */
	display:none;
	}
	
	.de_form input[type="radio"] + label:before{
	content:"\f111";
	font-family:"FontAwesome";
	margin-right:10px;
	}
	
	.de_form input[type="checkbox"] + label:before{
	content:"\f00c";
	font-family:"FontAwesome";
	margin-right:10px;
	}

	/* style your lables/button */
	.de_form input[type="radio"] + label, 
	.de_form input[type="checkbox"] + label {
		/* keep pointer so that you get the little hand showing when you are on a button */
		cursor: pointer;
		/* the following are the styles */
		padding: 4px 10px;
		border: none;
		background: #222;
		color: #606060;
		border-radius: 3px;
	}
	
	.de_light .de_form input[type="radio"] + label, 
	.de_light .de_form input[type="checkbox"] + label {
		background: #eee;
		color:#999;
	}
	
	.de_form.no-bg input[type="radio"] + label, 
	.de_form.no-bg input[type="checkbox"] + label {
		padding: 4px 0px;
		border: none;
		background:none;
	}

	.de_form input[type="radio"]:checked + label,
	.de_form input[type="checkbox"]:checked + label{
		/* style for the checked/selected state */
		border: none;
		color: white;
	}
	
	.de_light .de_form input[type="radio"]:checked + label,
	.de_light .de_form input[type="checkbox"]:checked + label{
		/* style for the checked/selected state */
		color: #333;
	}
	
	/* heading */
	/* ------------------------------ */
	.form-transparent input[type=text], .form-transparent textarea, .form-transparent input[type=email]{
	padding:10px;
	margin-bottom:20px;
	color:#fff;
	border:none;
	background:rgba(0,0,0,.2);
	border-radius:0 !important;
	height:auto;
	}
	
	/* heading */
	/* ------------------------------ */
	h1, h2, h3, h4, h5, h6 {
	color: #000;
	font-family: 'Dosis',Arial, Helvetica, sans-serif;
	font-weight:400;
	}
	
	h1 {
	font-size:28px;
	margin:0;
	text-align:center;
	letter-spacing:5px;
	text-transform:uppercase;
	font-weight:300;
	}
	
	h1 .small-border{
		margin-top:30px;
	}
	
	h2 {
	display:inline-block;
	font-weight:500;
	margin-bottom:30px;
	line-height:1.2em;
	}
	h2 .small-border{
	margin-left:0;
	margin-bottom:15px;
	width:40px;
	}
	h2{
	}
	h3 {
	margin-top: 0;
	font-size:18px;
	letter-spacing:3px;
	text-transform:uppercase;
	margin-bottom:15px;
	}
	h3.size-2{
	font-size:24px;
	}
	h4{
	font-size:18px;
	}
	h1.slogan_big {
	font-weight: 300;
	font-size: 64px;
	line-height: 64px;
	letter-spacing: -2px;
	padding: 0;
	margin: 0px 0 30px 0;
	}
	h1.title{
	font-size:64px;
	letter-spacing:10px;
	}
	h1.title.s2{
	font-size:64px;
	letter-spacing:0px;
	}
	h1.title strong{
	font-family:"Allura";
	text-transform:none;
	letter-spacing:0;
	font-weight:normal;
	}
	h1.title-2{
	letter-spacing:20px;
	text-transform:uppercase;
	line-height:50px;
	}
	h1.title-3{
	font-size:72px;
	}
	h1.title-3 strong{
	font-family:"Allura";
	text-transform:none;
	letter-spacing:0;
	font-weight:normal;
	}
	h2.subtitle{
	margin-top:0;
	}
	h2.hero{
	font-size:32px;
	font-weight:400;
	text-transform:uppercase;
	letter-spacing:32px;
	}
	h2.style-2{
	font-size:12px;
	font-weight:bold;
	text-transform:uppercase;
	letter-spacing:10px;
	}
	h2.style-3{
	font-size:64px;
	font-weight:400;
	text-transform:uppercase;
	letter-spacing:20px;
	}
	h2.style-4{
	font-size:60px;
	font-weight:400;
	text-transform:normal;
	letter-spacing:-2px;
	}
	h2.style-5{
	margin-top:0;
	line-height:1.2em;
	font-size:48px;
	font-weight:400;
	text-transform:normal;
	}
	.de_light .text-light h2{
	color:#fff;
	}
	h3.title {
	border-bottom: solid 1px #ddd;
	margin-bottom: 20px;
	}
	h4.title {
	border-bottom: solid 1px #ddd;
	padding-bottom: 10px;
	margin-bottom: 20px;
	}
	h5 {
	font-weight: bold;
	}
	h5.s2{
	font-weight:500;
	text-transform:uppercase;
	font-size:12px;
	letter-spacing:2px;
	}
	h1,
	h2,
	h3,
	h4,
	h5{
	color:#eceff3;
	}
	
	h3.s2 span{
		font-family:"Allura";
		text-transform:none;
		letter-spacing:0;
		font-size:40px;
		display:block;
	}
	
	h3.s2{
	font-size:30px;
	}
	
	h3.style-1{
		font-family:"Open Sans";
		letter-spacing:0;
		text-transform:none;
		margin-bottom:20px;
	}

	#section-services .owl-carousel h3{height:38px;}
	#section-services .owl-carousel p{height:150px;}
	
	.typing-wrap{
	font-family:"Dosis";
	font-size:30px;
	margin:0;
	line-height:60px;
	}
	
	span.teaser{
	font-family:"Georgia";
	font-style:italic;
	font-size:18px;
	}
	
	.text-light,
	.text-light p{
	color:#ddd;
	}
	
	.text-dark{
	color:#333;
	}
	
	.text-dark, .text-dark h1, .text-dark h2, .text-dark h3{
	color:#222;
	}
	
	.text-light h1{
	color:#fff !important;
	}	
	
	.de_light h1,.de_light h2,.de_light h3,.de_light h4,.de_light h5,.de_light h6{
	color:#333;
	}
	
	.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5{
	color:#fff;
	}
	
	/* feature box style 1 */
	.feature-box i {
	border:solid 1px;
	}
	
	.feature-box-small-icon {
	margin-bottom: 30px;
	}
	
	.feature-box-small-icon.center{
	text-align:center;
	}
	
	.feature-box-small-icon .inner:hover > i {
	color:#fff;
	}
	
	.feature-box-small-icon .text {
	padding-left: 70px;
	}
	
	.feature-box-small-icon.center .text{
	padding-left:0;
	display:block;
	}
	
	.feature-box-small-icon i {
	text-shadow:none;
	color: #333;
	font-size: 32px;
	width: 68px;
	height: 68px;
	text-align: center;
	position: absolute;
	border-radius:60px;
	}
	
	.feature-box-small-icon i.hover {
	color:#333 !important;
	}
	
	.feature-box-small-icon i.hover {
	color:#fff !important;
	}
	
	.feature-box-small-icon .border{
	height:2px;
	width:30px;
	background:#ccc;
	display:block;
	margin-top:20px;
	margin-left:85px;
	}
	
	
	.feature-box-small-icon .btn{
	margin-top:10px;
	}
	
	.feature-box-small-icon.center i{
	position:inherit;
	float:none;
	display:inline-block;
	margin-bottom:20px;
	border:none;
	font-size:60px;
	background:none;
	padding:0px;
	}
	
	.feature-box-small-icon.center .fs1{
	position:inherit;
	float:none;
	display:inline-block;
	margin-bottom:20px;
	border:none;
	font-size:60px;
	background:none;
	padding:0px;
	}
	

	.feature-box-small-icon i{
	background-color: #333333;
	}
	
	.feature-box-small-icon h3 {
	line-height: normal;
	margin-bottom: 5px;
	}
	
	.feature-box-small-icon.no-bg h2 {
	padding-top:12px;
	}
	
	.feature-box-small-icon.no-bg .inner, .feature-box-small-icon.no-bg .inner:hover{
	padding:0;
	background:none;
	}
	
	.f-hover{
	-o-transition:.5s;
	-ms-transition:.5s;
	-moz-transition:.5s;
	-webkit-transition:.5s;
	transition:.5s;
	 outline: none;
	}
	
	/* feature-box style 2 */
	.feature-box i {
	border:solid 1px;
	}
	
	.feature-box-small-icon-2 {
	margin-bottom: 30px;
	}
	
	.feature-box-small-icon-2 .text {
	padding-left: 48px;
	}
	
	.feature-box-small-icon-2 i {
	text-shadow:none;
	color:#eceff3;
	font-size: 24px;
	text-align: center;
	position: absolute;
	}
	
	.feature-box-small-icon-2 i{
	background-color: #333333;
	}
	
	.feature-box-small-icon-2 h3 {
	line-height: normal;
	margin-bottom: 5px;
	}
	
	.feature-box-small-icon-2.no-bg h2 {
	padding-top:12px;
	}
	
	.feature-box-small-icon-2.no-bg .inner, .feature-box-small-icon-2.no-bg .inner:hover{
	padding:0;
	background:none;
	}
	
	
	/* feature-box image style 3 */
	.feature-box i {
	border:solid 1px;
	}
	.feature-box-image-2 {
	margin-bottom: 30px;
	}
	.feature-box-image-2 .text {
	padding-left: 100px;
	}
	.feature-box-image-2 img {
	position: absolute;
	}
	.feature-box-image-2 i{
	background-color: #333333;
	}
	.feature-box-image-2 h3 {
	line-height: normal;
	margin-bottom: 5px;
	}
	.feature-box-image-2.no-bg h2 {
	padding-top:12px;
	}
	.feature-box-image-2.no-bg{
	}
	.feature-box-image-2.no-bg .inner, .feature-box-image-2.no-bg .inner:hover{
	padding:0;
	background:none;
	}
	
	/* with image */
	.feature-box-image img{
	width:100%;
	margin-bottom:10px;
	height:auto;
	}
	.feature-box-image h3 {
	line-height: normal;
	margin-bottom: 5px;
	font-size:20px;
	font-weight:bold;
	}
	
	/* feature-box image style 4 */
	.feature-box-big-icon{
	text-align:center;
	}
	.feature-box-big-icon .text{
	}
	.feature-box-big-icon i {
	float:none;
	display:inline-block;
	text-shadow:none;
	color:#eceff3;
	font-size: 40px;
	padding: 40px;
	width: 120x;
	height: 120x;
	text-align: center;
	border:none;
	border-radius:10px;
	-moz-border-radius:10px;
	-web-kit-border-radius:10px;
	background:#333;
	margin-bottom:40px;
	
	cursor:default;
	
	
	}
	.feature-box-big-icon i:after {
	content: "";
	position:absolute;
	margin:75px 0 0 -40px;
	border-width: 20px 20px 0 20px; /*size of the triangle*/
	border-style: solid;
	}
	
	.feature-box-big-icon:hover i,
	.feature-box-big-icon:hover i:after{
	-o-transition:.5s;
	-ms-transition:.5s;
	-moz-transition:.5s;
	 -webkit-transition:.5s;
	 transition:.5s;
	}
	.feature-box-big-icon:hover i{
	background:#333;
	}
	.feature-box-big-icon:hover i:after{
	border-color:#333 transparent;
	}
	
	
	.box-fx .inner{
	position:relative;
 	overflow:hidden;
	border-bottom:solid 2px;
	}
	
	.box-fx .inner{
	border-bottom:solid 2px;
	}
	
	.box-fx .front{
	padding:60px 0 60px 0;
	}
	
	.box-fx .front span{
	font-size:14px;
	}
	
	.box-fx .info{
	position:absolute;
	padding:60px 30px 60px 30px;
	background:#fff;
	color:#fff;
	}
	
	.box-fx i{
	font-size:72px !important;
	margin-bottom:20px;
	}
	
	.box-fx .btn-line, .box-fx .btn-line a.btn-line{
	border:solid 1px rgba(255,255,255,0.5);
	color:#fff !important;
	}
	
	.box-fx .btn-line:hover, .box-fx a.btn-line:hover{
	background:#fff;
	color:#111 !important;
	border-color:#fff;
	}
	
	.box-fx .btn-line:after, .box-fx a.btn-line:after{
	color:#fff !important;
	}
	
	.box-fx .btn-line:hover:after, .box-fx a.btn-line:hover:after, .box-fx a.btn-line.hover:after{
	color:#111 !important;
	}
	
	.box-fx .bg-icon{
		left:50%;
		bottom:50px;
		color:#f5f5f5 !important;
		position:absolute !important;
		font-size:190px !important;
		z-index:-1;
	}
	
	.box-icon-simple.right{
	margin-right:70px;
	text-align:right;	
	}
	
	.box-icon-simple.right i{
	font-size:42px;
	right:0;
	position:absolute;
	}
	
	.box-icon-simple.left{
	margin-left:70px;
	}
	
	.box-icon-simple.left i{
	font-size:42px;
	left:0;
	position:absolute;
	}
	
	
	.box-number .number{
	display:block;
	font-size:48px;
	color:#222;
	width:80px;
	height:80px;
	text-align:center;
	padding:25px;
	border-radius:60px;
	position:absolute;
	}
	
	.box-number.square .number{
	border-radius:0;
	}
	
	.box-number .text{
	margin-left:100px;
	}
	
	.box-icon .icon{
	display:block;
	font-size:48px;
	color:#fff;
	text-align:center;
	border-radius:60px;
	position:absolute;
	cursor:default;
	}
	
	.de_light .box-icon .icon{
	color:#222;
	}
	
	.box-icon .text{
	margin-left:80px;
	}
	
	.box-icon.line{
	border:solid 1px rgba(255,255,255,.1);
	padding:40px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	}
	
	.de_light .box-icon.line{
	border:solid 1px rgba(0,0,0,.05);	
	}
	
	.box-icon.line.active,
	.box-icon.line:hover{
		background:rgba(255,255,255,.1);
		border-color:rgba(255,255,255,.0);
	}
	
	.de_light .box-icon.line.active,
	.de_light .box-icon.line:hover{
		background:rgba(0,0,0,.05);	
		border-color:rgb(0,0,0,.0);	
	}
	
	.box-icon.line a{
		text-decoration:none;
	}
	
	.box-icon.center{
		text-align:center;
	}
	.box-icon.center .text{
		margin-left:0px;
	}
	.box-icon.center .icon{
		display:inline-block;
		float:none;
		position:static;
		margin-bottom:20px;
	}
	
	.box-icon.top .text{
		margin-left:0px;
	}
	.box-icon.top .icon{
		display:inline-block;
		float:none;
		position:static;
		margin-bottom:20px;
	}
	
	/* list */
	.ul-style-2{
	padding:0;
	list-style:none;
	}
	
	.ul-style-2 li{
	margin:5px 0 5px 0;
	}
	
	.ul-style-2 li:before{
	font-family:"FontAwesome";
	content:"\f00c";
	margin-right:15px;
	}
	
	/* pagination */
	/* ======================================== */
	
	.pagination li a:hover{
	color:#fff;
	background:none;	
	}
	
	.de_light .pagination li a:hover{
	color:#111;
	background:none;	
	}
	
	.pagination li a{
	color:#888;
	border:solid 1px #404040;
	background:none;
	margin:3px;
	padding:15px 20px 15px 20px;	
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	}
	
	.de_light .pagination li a{
	border-color:#ccc;
	}
	
	/* pricing table */
	/* ================================================== */
	.pricing-box{color:#888;}
	
	.pricing-2-col {
	border-right: solid 1px #eee;
	}
	
	.pricing-2-col .pricing-box {
	width: 49%;
	}
	
	.pricing-3-col {
	}
	
	.pricing-3-col .pricing-box {
	width: 32.33%;
	}
	
	.pricing-4-col {
	}
	
	.pricing-4-col .pricing-box {
	width: 24%;
	}
	
	.pricing-5-col {
	}
	
	.pricing-5-col .pricing-box {
	width: 19%;
	}
	
	.pricing-2-col,
	.pricing-3-col,
	.pricing-4-col,
	.pricing-5-col{
	}
	
	
	.pricing-box {
	float: left;
	text-align: center;
	margin:5px;
	padding: 0;
	opacity:.8;
	}
	
	.pricing-box ul {
	margin: 0;
	padding: 0;
	}
	
	.pricing-box li {
	list-style: none;
	margin: 0;
	padding: 15px 0 15px 0;
	background:#eee;
	}
	
	.pricing-box li h4 {
	font-size: 22px;
	margin: 0;
	padding: 0;
	font-weight: 400;
	color:#888;
	}
	
	.pricing-box li h1 {
	font-size: 48px;
	margin: 0;
	margin-bottom: 5px;
	color: #1a8b49;
	letter-spacing:0px;
	}
	
	.pricing-box li.title-row {
	padding: 15px 0 15px 0;
	}
	
	.pricing-box li.title-row h4{
	}
	
	.pricing-box li.price-row {
	padding: 15px 0 15px 0;
	background:#F8F8F8;
	
	}
	.pricing-box li.deco{
	background:#f5f5f5;
	}
	
	.pricing-box a.btn{
	color:#eceff3;
	}
	
	.pricing-box li.price-row span {
	display: block;
	font-weight: 600;
	}
	
	.pricing-box li.btn-row {
	background: #ddd;
	}
	.pricing.pricing-box{
	}
	.pricing.pricing-box ul {
	margin: 0;
	padding: 0;
	}
	
	.pricing.pricing-box li {
	background: #3b3b3b;
	color: #bbb;
	list-style: none;
	margin: 0;
	padding: 15px 0 15px 0;
	}
	
	.pricing.pricing-box li.deco{
	background:#333;
	}
	
	
	
	.pricing.pricing-box li h4 {
	font-size: 22px;
	margin: 0;
	padding: 0;
	font-weight: 400;
	color:#eceff3;
	}
	
	.pricing.pricing-box li h1 {
	font-size: 48px;
	margin: 0;
	margin-bottom: 5px;
	font-weight: 600;
	color:#eceff3;
	}
	
	.pricing.pricing-box li.title-row {
	background:#323232;
	padding: 15px 0 15px 0;
	}
	
	.pricing.pricing-box li.price-row {
	background: #2b2b2b;
	padding: 15px 0 15px 0;
	}
	
	.pricing.pricing-box li.price-row h1{
	font-weight: 300;
	}
	
	.pricing.pricing-box li.price-row h1 span {
	display:inline-block;
	font-weight: 600;
	}
	
	.pricing.pricing-box li.price-row span {
	display: block;
	font-weight: 600;
	}
	
	.pricing.pricing-box li.btn-row {
	background: #404040;
	}
	
	
	.pricing-featured {
	position: relative;
	}
	
	.pricing-featured li{
	padding-top:17px;
	padding-bottom:17px;
	}
	
.pricing-s1 {
	overflow:hidden;
	position:relative;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	transition: box-shadow 1s;
	-webkit-transition: box-shadow 1s;
	-moz-transition: box-shadow 1s;
}
.pricing-s1 .top {
	background: #222427;
	color: #eee;
	padding: 40px;
	color: #ECF0F1;
	text-align: center;
}
.pricing-s1 .top h2 {
	font-size: 24px;
	margin-bottom: 20px;
	font-weight: normal;
}
.pricing-s1 .top > * {
	margin: 0px;
}
.pricing-s1 .top .price .currency {
	font-size: 24px;
	line-height: 54px;
	vertical-align: top;
	display: inline-block;
}
.pricing-s1 .top .price b {
	font-size: 48px;
	line-height: 70px;
	color: #fff;
}
.pricing-s1 .top .price .month {
	color: #ccc;
}
.pricing-s1 .bottom {
	border-top: 0px;
	padding-bottom:5px;
	background: #222427;
	text-align:center;	
}
.pricing-s1 .bottom ul {
	list-style: none;
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	text-align:left;
}
.pricing-s1 .bottom ul li {
	line-height: 60px;
	padding: 0px 30px;
}
.pricing-s1 .bottom ul li > span {
	color: #16A085;
	font-size: 20px;
	margin-right: 20px;
}
.pricing-s1 .bottom ul li:nth-child(odd) {
	background:#2f3135;
}
.pricing-s1 .bottom ul li:nth-child(even) {
	background:#2a2c30
}
.pricing-s1 .bottom > a {
	margin: 40px;
	width: auto;
}
.pricing-s1 .bottom i{
	margin-right:10px;
	font-size:14px;
}
.pricing-s1 .ribbon{
  width: 200px;
  position: absolute;
  top: 20px;
  left: -55px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #222;
  font-weight:bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

/* light version */

.pricing-s1.light .top {
	background: #eeeeee;
}
.pricing-s1.light .top h2 {
	font-size: 20px;
	margin-bottom: 20px;
}
.pricing-s1.light .top .price b {
	font-size: 48px;
	line-height: 70px;
	font-weight:500;
	color: #333;
}
.pricing-s1.light .top .price .month {
	color: #555;
}
.pricing-s1.light .bottom {
	background: #e5e5e5;
}
.pricing-s1.light .bottom ul li:nth-child(odd) {
	background:#f5f5f5;
}
.pricing-s1.light .bottom ul li:nth-child(even) {
	background:#eeeeee;
}
.pricing-s1.light .btn-custom{
	letter-spacing:1px;
	color:#333;
}

.pricing-s2 {
	background:#f9f9f9;
	overflow:hidden;
	position:relative;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	transition: box-shadow 1s;
	-webkit-transition: box-shadow 1s;
	-moz-transition: box-shadow 1s;
}
.pricing-s2 .top {
	padding: 40px;
	text-align: center;
}
.pricing-s2 .top h2 {
	font-size: 18px;
	margin-bottom: 20px;
	font-weight: 500;
}
.pricing-s2 .top > * {
	margin: 0px;
}
.pricing-s2 .top .price .currency {
	font-size: 24px;
	line-height: 54px;
	vertical-align: top;
	display: inline-block;
}
.pricing-s2 .top .price b {
	font-size: 48px;
	line-height: 70px;
	color: #333;
}
.pricing-s2 .top .price .month {
}
.pricing-s2 .bottom {
	border-top: 0px;
	padding-bottom:5px;
	text-align:center;	
}
.pricing-s2 .bottom ul {
	list-style: none;
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	text-align:left;
}
.pricing-s2 .bottom ul li {
	padding: 10px 30px;
}
.pricing-s2 .bottom ul li > span {
	color: #16A085;
	font-size: 20px;
	margin-right: 20px;
}
.pricing-s2 .bottom ul li:nth-child(odd) {
	background:#fcfcfc;
}
.pricing-s2 .bottom ul li:nth-child(even) {
	background:#f5f5f5;
}
.pricing-s2 .bottom > a {
	margin: 40px;
	width: auto;
}
.pricing-s2 .bottom i{
	margin-right:10px;
}
.pricing-s2 .ribbon{
  width: 200px;
  position: absolute;
  top: 20px;
  left: -55px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #222;
  font-weight:bold;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  background:#000000;
  color:#ffffff;
}
	
	/* progress bar */
	/* ================================================== */
	
	.de-progress {
	width: 100%;
	height: 4px;
	background: #ddd;
	margin-top: 20px;
	margin-bottom: 20px;	
	border-radius:10px;
	}
	.de-progress .progress-bar {
		background: #333;
		height: 4px;
		box-shadow: none;
		border-radius:10px;
	}
	.de-progress {
		background: rgba(255, 255, 255, .2);
	}
	.skill-bar {
		margin-bottom: 40px;
	}
	.skill-bar.style-2 .de-progress{
		background: rgba(1, 1, 1, .1);
	}
	.skill-bar h5{
	font-weight:500;
	}
	.skill-bar .value{
		color:#888;
		font-size:12px;
		font-weight:light;
		float:right;
		margin-top:-40px;
	}
	
	
	/* social-icons */
	/* ================================================== */
	.social-icons{
	display:inline-block;
	}
	.social-icons i{
	text-shadow:none;
	color: #fff;
	padding: 8px;
	width: 28px;
	height: 28px;
	text-align: center;
	font-size:16px;
	border-radius:1px;
	-moz-border-radius:1px;
	-webkit-border-radius:1px;
	}
	footer.light .social-icons i{
	color: #606060;
	}
	.social-icons i:hover{
	background:#fff;
	border-color:#eceff3;
	color:#111111;
	}
	
	.social-icons.light i{
	color:#111111;
	}
	
	/*
	.social-icons .fa-facebook{	background:#305fb3;	}
	.social-icons .fa-twitter{	background:#00d7d5;	}
	.social-icons .fa-rss{ background:#fca600;	}
	.social-icons .fa-google-plus{ background:#d7482a;	}
	.social-icons .fa-skype{ background:#12c7ef;	}
	.social-icons .fa-dribbble{ background:#f06eaa;	}
	*/
	
	/* tabs */
	/* ================================================== */
	.de_tab {
	opacity:.8;
	}
	
	.de_tab .de_nav {
	overflow: hidden;
	padding-left: 0;
	margin: 0;
	padding: 0;
	font-weight:bold;
	}
	
	.de_tab .de_nav li {
	float: left;
	list-style: none;
	background: #EEE;
	margin-right: 5px;
	}
	
	.de_tab .de_nav li span {
	padding: 8px 16px 8px 16px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	display: block;
	background: #f5f5f5;
	text-decoration: none;
	color: #646464;
	font-size: 13px;
	cursor: pointer;
	}
	
	.de_tab.tab_steps .de_nav{
	text-align:center;	
	}
	.de_tab.tab_steps .de_nav li{
	background:none;
	display:inline-block;
	float:none;
	margin:0 30px 0px 30px;
	}
	
	.de_tab.tab_steps .de_nav li span {
	padding:30px 0 30px 0;
	color:#222;
	font-family:"Dosis";
	font-size:16px;
	border-radius:120px;
	-moz-border-radius:120px;
	-webkit-border-radius:120px;
	line-height:120px;
	width:180px;
	height:180px;
	text-align:center;
	text-transform:uppercase;
	}
	
	.de_tab.tab_steps.square .de_nav li span{
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	}
	
	.de_tab.tab_steps .de_nav li span:hover{
	background:#000;
	border:none;
	color:#fff;
	}
	
	.de_tab.tab_steps .de_nav li .v-border{
	height:60px;
	border-left:solid 1px rgba(255,255,255,.3);
	display:inline-block;
	margin-bottom:-5px;	
	}
	
	.de_tab.tab_steps.dark .de_nav li .v-border{
	border-left:solid 1px rgba(0,0,0,.3);
	}
	
	.de_tab .de_nav li span {
	border:none;
	background:#222;
	}
	
	.de_tab .de_nav li:first-child span {
	border-left: 1px solid #ddd;
	}
	
	.de_tab .de_nav li:first-child span {
	border-left: 1px solid #404040;
	}
	
	
	.de_tab .de_tab_content {
	border: 1px solid #ddd;
	padding: 15px 20px 20px 20px;
	margin-top: -1px;
	background: #fff;
	}
	
	.de_tab .de_tab_content {
	border: 1px solid #404040;
	background: #27282b;
	}
	
	.de_tab.tab_steps .de_tab_content {
	background:0;
	padding:0;
	border:none;
	padding-top:30px;
	border-top:solid 1px rgba(255,255,255,.4);
	}
	
	.de_tab.tab_steps.dark .de_tab_content {
	border-top:solid 1px rgba(0,0,0,.4);
	}
	
	.de_tab .de_nav li.active span {
	background: #FFF;
	border-bottom: 1px solid #fff;
	color: #222;
	margin-bottom: -3px;
	}
	
	.de_tab .de_nav li.active span .v-border{
	display:none !important;
	}
	
	.de_tab .de_nav li.active span {
	background: #27282b;
	border-bottom: 1px solid #27282b;
	color: #ccc;
	}
	
	.de_tab.tab_steps .de_nav li.active span {
	background:none;
	color: #ffffff;
	border:solid 1px rgba(255,255,255,.6);
	}
	
	.de_tab.tab_steps.dark .de_nav li.active span {
	background:none;
	color: #333;
	border:solid 1px rgba(0,0,0,.8);
	}
	
	/* new added */
	.de_tab.tab_steps.tab_6 .de_nav li span{
	padding:20px 0 20px 0;
	font-size:14px;
	line-height:70px;
	width:120px;
	height:120px;
	}
	

	.tab-small-post ul {
	list-style: none;
	margin: 0;
	padding: 0;
	}
	
	.tab-small-post ul li {
	min-height: 60px;
	margin-bottom: 15px;
	padding-bottom: 10px;
	border-bottom: solid 1px #eeeeee;
	}
	
	.tab-small-post ul li{
	border-bottom: solid 1px #404040;
	}
	
	.tab-small-post ul li:last-child {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
	}
	
	.tab-small-post ul li img {
	position: absolute;
	}
	
	.tab-small-post ul li span {
	display: block;
	padding-left: 64px;
	}
	
	.tab-small-post ul li span.post-date {
	font-size: 11px;
	}
	
	.de_tab.timeline.nav_4 li{
	display:inline-block;
	text-align:center;
	background:none;
	width:24%;
	}	
	
	.de_tab.timeline.nav_4 li span{
	background:none;
	border:none;
	margin-top:20px;
	font-family:"Dosis";
	font-size:14px;
	text-transform:uppercase;
	letter-spacing:2px;
	}
	
	.de_tab.timeline.nav_4 .dot{
	display:block;
	width:8px;
	height:8px;
	background:#fff;
	opacity:1;
	margin:0 auto;
	border-radius:8px;
	margin-top:1px;
	}
	
	.de_tab.timeline.nav_4 .dot:before,
	.de_tab.timeline.nav_4 .dot:after {
	  content: "";
	  position: absolute;
	  height: 5px;
	  border-bottom: 1px solid rgba(255,255,255,.1);
	  top: 0;
	  width:24%;
	}
	
	.de_tab.timeline.nav_4  li:last-child .dot:before,
	.de_tab.timeline.nav_4 li:last-child .dot:after {
	 border-bottom: none;
	}
	
	.de_tab.timeline li .num{
	font-size:20px;
	}
	
	.de_tab.timeline .de_tab_content{
	background:none;
	border:none;
	padding-top:30px;
	}
	
	.de_tab .navigation_arrow{
	text-align:center;
	}
	
	
	
	.timeline .tl-block{ position: relative; margin-top: 10px; }
	.timeline .tl-block:after{ content: ' '; clear: both; display: block;}
	.timeline .tl-block .tl-time{ float: left; padding:10px 20px 0px 20px; margin-top:-15px; border:solid 1px #ddd; }
	.timeline .tl-block .tl-time:after{width:50px; height:1px; background:#ddd;}
	.timeline .tl-block .tl-bar{float: left; width: 5%; position: absolute; margin-left:114px; }
	.timeline .tl-block .tl-line{background: #fff; margin-right: 10px;  border-radius: 10px; width: 6px; height: 6px; font-size: 10px; text-align: center; line-height: 15px;}
	.timeline .tl-message{ border-radius: 3px; float: left; margin-left:150px; top:0; margin-top:-50px; margin-bottom:30px; }
	.timeline .tl-block .tl-message:after{ display: block; content: ' '; clear:both; }
	.timeline .tl-block .tl-message .tl-icon{float: left; left:100px; }
	.timeline .tl-block .tl-message .tl-main{ float: left; }
	.timeline .tl-block .tl-message .tl-main .tl-title {}
	.timeline .tl-block .tl-message .tl-main .tl-content{}
	.timeline .tl-block .tl-message .tl-main .tl-content label{ font-weight: bold; display: inline-block; width: 80px;}
	.timeline .tl-block .tl-message .tl-main .tl-content span{}
	.timeline .tl-block .tl-message h3{text-transform:none;}
	.timeline .tl-block .tl-time h4{margin:0 0 10px 0;}
	.timeline .tl-block .tl-main h4{margin-top:-5px;}
	.timeline.exp:before{left: 214px;}
	.timeline.exp .tl-message{margin-left:250px;}
	.timeline.exp .tl-block .tl-bar{margin-left:180px; }
	.timeline.exp{ margin-top: 20px; position: relative; padding: 10px}
	.timeline.exp:before{ 
		content: '';
		position: absolute;
		top: 0;
		left: 193px;
		height: 100%;
		width: 1px;
		background:rgba(255,255,255,.1);
	}
	
	
	.de_light .timeline .tl-block .tl-time{ border:solid 1px rgba(0,0,0,.3); }
	.de_light .timeline .tl-block .tl-time:after{ background:rgba(0,0,0,.3); }
	.de_light .timeline .tl-block .tl-line{background: #333;}
	.de_light .timeline.exp:before{ background:rgba(0,0,0,.3); }
	
	.btn-left,
	.btn-right{
	background:none;
	border:solid 1px rgba(255,255,255,.2);
	padding:10px 20px 10px 20px;
	margin:5px;
	font-family:"Dosis";
	text-transform:uppercase;
	letter-spacing:5px;
	}
	
	.btn-right:after{
	font-family:FontAwesome;
	content: "\f054";
	padding-left:10px;
	}
	
	.btn-left:before{
	font-family:FontAwesome;
	content: "\f053";
	padding-right:10px;
	}
	
	a.btn-left,
	a.btn-right{
	text-decoration:none;
	}
	
	.expand-group .expand:last-child{
		border-bottom:solid 1px rgba(255,255,255,.1);
	}
	.de_light .expand-group .expand:last-child{
		border-bottom:solid 1px rgba(0,0,0,.1);
	}
	
	.expand{
		position:relative;
		padding:15px 25px 5px 25px;
		border:solid 1px rgba(255,255,255,.1);
		border-bottom:none;
		background:rgba(255,255,255,.02);
	}
	
	.de_light .expand{
		border:solid 1px rgba(0,0,0,.1);
		border-bottom:none;
		background:none;
	}
	
	.expand .hidden-content{
		display:none;
		padding:10px 0 10px 0;
	}
	
	.expand h4{
		font-family:"Open Sans";
		font-size:14px;
		font-weight:600;
		cursor:pointer;
		display:block;
		margin-right:40px;
		line-height:32px;
	}
	
	.expand h4:after{
		font-family:FontAwesome;
		content: "\f107";
		color:#fff;
		font-size:20px;
		display:inline-block;
		padding:10px 20px 10px 20px;
		font-style:normal;
		position:absolute;
		right:7px;
		top:7px;
	}
	
	.expand h4.active:after{
		content: "\f106";
	}
	
	
	.expand-group.s2 .expand:last-child{
		border-bottom:solid 1px #cccccc;
	}
	
	.expand-group.s2 .expand{
		border:none;
		background:none;
		border-top:solid 1px #cccccc;
		padding-left:0;
	}
	
	.expand-group.s2 .expand h4:after{
		padding-right:0;
	}
	
	/* team
	/* ================================================== */
	.team {
	margin-bottom: 30px;
	text-align:center;
	}
	
	.team h5 {
	margin-bottom: 0;
	}
	
	.team_photo {
	margin-bottom: 10px;
	}
	
	.team img.team-pic {
	width: 100%;
	height: auto;
	}
	
	.pic-team img{
	width: 100%;
	height: auto;
	width:150px;
	height:150px;
	position:absolute;
	}
	
	.pic-team img.pic-hover{
	z-index:100;
	display:none;
	}
	
	.team .team_desc {
	font-style: italic;
	margin-bottom: 10px;
	}
	
	.team .sb-icons {
	padding: 10px 0 0 0;
	}
	
	.team .sb-icons img {
	margin-right: 5px;
	}
	
	.team .columns {
	margin-bottom: 30px;
	}
	
	.team-list{
	min-height:180px;
	margin-bottom:40px;
	}
	
	.team-list .team-pic{
	width:150px;
	height:150px;
	position:absolute;
	}
	
	.team-list h5{
	margin-top:0;
	margin-bottom:10px;
	font-size:14px;
	}
	
	.team-list.team_desc{
	font-style:italic;
	margin-bottom:10px;
	}
	
	.team-list .small-border{
	float:none;
	margin:15px 0 15px 0;
	background:#ddd;
	width:50px;
	}
	
	.team-list .social{
	width:150px;
	margin-top:170px;
	text-align:center;
	position:absolute;
	}
	
	
	.team-list .social a{
	display:inline-block;
	}
	
	.team-list .info{
	padding-left:170px;
	}
	
	.de-team-list{
		position:relative;
		overflow:hidden;
	}
	
	.de-team-list img{
		width:100%;
	}
	
	.de-team-list .team-desc{
		width:100%;
		height:100%;
		padding:20px;
		padding-bottom:0;
		position:absolute;
		z-index:100;
		top:100%;
		background:#111;
		color:#ccc;
	}
	
	.de-team-list p{
		clear:both;
	}
	
	.de-team-list .team-desc h3{
		color:#fff;
		margin-bottom:5px;
	}
	
	.de-team-list .social{
	}
	
	.de-team-list .social a{
	display:inline-block;
	padding:0 5px 0 5px;
	}
	
	.de-team-list p.lead{
		font-size:14px;
	}
	
	.de-team-list .small-border{
	float:none;
	margin:15px 0 15px 0;
	background:#ddd;
	width:50px;
	}
	
	.de-team-list .social a:hover{
	color:#fff;
	}
	
	.de-team-list:hover .team-desc{
		top:0;
	}
	


	/* testimonial
	/* ================================================== */
	.de_testi {
	display: block;
	margin-bottom: 10px;
	}
	
	.de_testi blockquote {
	margin: 0;
	border: none;
	padding: 20px 30px 30px 80px;
	background:rgba(0,0,0,0.5);
	font-style:normal;
	line-height:1.6em;
	font-weight:500;
	}
	
	.de_testi blockquote  p{
	margin-top:10px;
	}
	
	.de_testi blockquote:before{
	font-family:FontAwesome;
	content: "\f10d";
	color:#111;
	padding-bottom:10px;
	font-size:20px;
	display:inline-block;
	padding:10px 20px 10px 20px;
	font-style:normal;
	background:#000;
	position:absolute;
	left:0;
	top:0;
	}
	
	.de_testi blockquote {
	color:#eceff3;
	}
	
	.de_testi blockquote, .de_testi blockquote p {
	font-size:16px;
	line-height: 1.6em;
	font-weight: 500;
	}
	
	.de_testi_by {
	margin-top: -10px;
	font-style:normal;
	font-size:12px;
	}
	
	.de_testi_pic {
	float: left;
	padding-right: 15px;
	}
	
	.de_testi_pic img {
	width: 50px;
	height: 50px;
	}
	
	.de_testi_company {
	padding-top: 20px;
	}
	
	.de_testi.s2 blockquote {
	background:#f6f6f6;
	color:#606060;
	}
	.de_testi.s2 blockquote:before{
	color:#ffffff;
	}
	
	#testimonial-masonry .item{
	margin-bottom:30px;
	}

		



/* ================================================== */
/* anim */
/* ================================================== */
de_tab .de_nav li span,
.feature-box-small-icon i, a.btn-line:after, .de_count, .social-icons a i,
.de_tab.tab_steps .de_nav li span, .de_tab.tab_steps .de_nav li span:hover,
.de-gallery .overlay .icon i,
.de-gallery .overlay .icon i:hover,
header.smaller:not(.header-bottom),
#contact_form input[type=submit],
#contact_form input[type=submit]:hover,
.product img,
.product img:hover,
.mfp-close,.mfp-close:hover,
header.de_header_2 .info,
header.de_header_2.smaller .info,
.de_form input[type="radio"] + label,
.de_form input[type="checkbox"] + label,
.de-gallery.hover-1 span.overlay-1,
.de-gallery.hover-1 span.overlay-1 .pf_text,
.picframe .overlay-v,
#mainmenu li,
#mainmenu li ul,
.box-icon.line,
.owl-theme .owl-controls .owl-buttons div,
.pic-hover .bg-overlay,
.pic-hover:hover > .bg-overlay,
.pic-hover.hover-scale img,
.picframe img,
.de-team-list .team-desc,
.de-team-list:hover .team-desc
{
-o-transition:.5s;
-ms-transition:.5s;
-moz-transition:.5s;
-webkit-transition:.5s;
transition:.5s;
 outline: none;
}

.picframe img
{
-o-transition:1s;
-ms-transition:1s;
-moz-transition:1s;
-webkit-transition:1s;
transition:1s;
 outline: none;
}


/* ================================================== */
/* video */
/* ================================================== */
video, object {
display:inline-block;
vertical-align:baseline;
min-width:100%;
min-height:100%;
}

.de-video-container{
top:0%;
left:0%;
height:500px;
width:100%;
overflow: hidden;
}

.de-video-content{
width:100%;
position:absolute;
z-index:10;
}


.de-video-overlay{
position:absolute;
width:100%;
min-height:100%;
background:url(../images/dot-70.png);
}

.de-video-overlay.dotted{
background:url(../images/dotted.png);
}

.video-fixed{
width:100%;
position:fixed;
top:0;
}

/* youtube, vimeo */

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}







.de-video-container .btn-line:hover:after, .de-video-container a.btn-line:hover:after, .de-video-container a.btn-line.hover:after{
color:#eceff3;
}






/* ================================================== */
/* map */
/* ================================================== */
/* --------------- map --------------- */
#map {
width: 100%;
height: 500px;
}

.map iframe {
width: 100%;
height: 320px;
border: solid 1px #ccc;
padding: 2px;
background: #fff;
}

.map-container{
height: 500px;
overflow:hidden;
}

/* ================================================== */
/* call to action */
/* ================================================== */

.call-to-action h2,
.call-to-action h3{
	margin:0;
}

/* ================================================== */
/* overide bootstrap */
/* ================================================== */
.container-fluid {
    padding:0px;
	margin:0px;
}


.btn-primary{
text-shadow: none;
border: none !important;
}

.btn.btn-primary:hover{
background:#555;
}


.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
}
.img-circle {
  border-radius: 50%;
}
.img-responsive {
  display: inline-block;
}

.label {
font-family: "Roboto",Arial, Helvetica, sans-serif;
}

table thead {
font-weight: bold;
}

p.lead,
p.intro{
	font-size:16px;
	font-weight:500;
	line-height:1.7em;
}

.table-bordered, .table-bordered td{
border:solid 1px #404040;
}

blockquote {
font-size: 24px;
font-style: italic;
line-height: 1.5em;
font-weight: 300;
}

blockquote span {
display: block;
font-size: 14px;
font-style: normal;
font-weight: normal;
}

blockquote.s1{
font-size:14px;
line-height:1.8em;
font-weight:500;
margin-left:30px;
padding:0 0 0 30px;
}

blockquote.testimonial-big{
	text-align:center;
	border:none;
	padding:0;
	margin:0;
	font-size:24px;
}
blockquote.testimonial-big .name{
	margin-top:20px;
}
blockquote.testimonial-big .title{
	margin-bottom:20px;
	font-size:26px;
	font-weight:bold;
}
blockquote.testimonial-big:before{
	font-family:FontAwesome;
	content: "\f10d";
	padding-bottom:10px;
	font-size:28px;
	display:inline-block;
	padding:10px 20px 10px 20px;
	font-style:normal;
}

.col-md-3{
margin-right:-1px;
}



/* ======================================== */
/* toggle */
/* ======================================== */
.toggle-list{list-style:none; margin:0; padding:0;}
.toggle-list li{ margin-bottom:10px; background:#fff; border:solid 1px #ddd;border-bottom:solid 3px #ddd;}
.toggle-list li h2{-moz-user-select: none; -html-user-select: none; user-select: none; color:#555;font-size:16px; line-height:20px; font-family:"Roboto",Arial, Helvetica, sans-serifr; padding:10px 20px 0px 60px; font-weight:700; display:block; letter-spacing:normal;}
.toggle-list li .acc_active:hover{ 
	color:#777;
	/* background:url(../images/icon-slide-plus.png) 20px center no-repeat;  */
padding-left:80px; 
-o-transition:.5s;
-ms-transition:.5s;
-moz-transition:.5s;
-webkit-transition:.5s;
transition:.5s;}
 
.toggle-list li .acc_noactive:hover{ color:#777;
/* background:url(../images/icon-slide-min.png) 20px center no-repeat; */
-o-transition:.5s;
-ms-transition:.5s;
-moz-transition:.5s;
-webkit-transition:.5s;
transition:.5s;}
.toggle-list .ac-content{display:none; padding:20px; border-top:solid 1px #e5e5e5;}
.acc_active{
	/* background:url(../images/icon-slide-plus.png) 20px center no-repeat; */
-o-transition:.5s;
-ms-transition:.5s;
-moz-transition:.5s;
-webkit-transition:.5s;
transition:.5s;
 height:32px;
 
 }
.acc_noactive{
	/* background:url(../images/icon-slide-min.png) 20px center no-repeat; */
}


/* ================================================== */
/* image position */
/* ================================================== */
.img-left {
float: left;
margin: 5px 15px 15px 0;
}

.img-right {
float: right;
margin: 5px 0 15px 15px;
}



/* ================================================== */
/* flickr */
/* ================================================== */
#flickr-photo-stream img {
width: 48px;
height: auto;
margin: 0 12px 12px 0;
float: left;
}

#flickr-photo-stream a img {
border: solid 2px #fff;
}

#flickr-photo-stream a:hover img {
border: solid 2px #777777;
}





hr{
border-top: solid 1px #555;
}

hr.blank {
border: none;
margin: 20px 0 20px 0;
}

/* ================================================== */
/* font awesome icons */
/* ================================================== */
i {
display: inline-block !important;
}

i.large {
font-size: 72px;
text-align: center;
display:inline-block;
float:none;
}

i.medium {
font-size: 20px;
padding: 10px;
text-align: center;
display:inline-block;
float:none;
}

i.small {
font-size: 12px;
padding: 4px;
text-align: center;
display:inline-block;
float:none;
}

i.circle-icon {
border-radius: 75px;
-moz-border-radius: 75px;
-webkit-border-radius: 75px;
}

.fa-android{
color:#469159;
}

.fa-apple{
color:#0EC3F7;
}

/* extra ^ */
.de_center p {
width: 100%;
}

.de_center .de_icon {
float: none;
margin: 0 auto;
}


/* ================================================== */
/* transition */
/* ================================================== */
a,
a:hover,
.test-column,
.test-column:hover {
-o-transition: .3s;
-ms-transition: .3s;
-moz-transition: .3s;
-webkit-transition: .3s;
transition: .3s;
}





.de_contact_info{
font-size:40px;
font-weight:bold;
}

.de_contact_info i{
float:none;
margin-right:20px;
}


.center-div{
margin:0 auto !important;
}





/* ================================================== */
/* gallery */
/* ================================================== */
.de_carousel .item {
width:100%;
display:block;
}


.item img {
width:100%;
height:auto;
}

.item h4 {
margin-bottom: 0;
font-size: 14px;
}


#gallery{
	visibility: visible;
	animation-delay: 0.3s;
	animation-name: fadeInUp;
	background-size: cover;
	position: relative;
	/* height: 637.188px !important; */
	opacity: 500;
}

#gallery .item{
overflow:hidden;
background-size: cover;
}

#gallery .overlay{
	opacity: 0;
    /* width: 479px; */
	width: 100%;
    height: 319px;
	padding: 0px 20px;
}

#gallery .item:hover .overlay{
	opacity: 0.9 !important;
}
#gallery .pf_text{
	margin-top: 119.5px;
  }
.picframe .overlay-v{
	text-align: center;
	width: 100px;
	background: rgba(0,0,0,.7);
	position:absolute;
	width:102%;
	height:100%;
	z-index:1000;
	display:table;
}
.picframe .overlay-v .pf_text{
	display:table-cell;
	vertical-align:middle;
}
.light .picframe .overlay-v{
	background: rgba(255,255,255,.3);
}

.pf_text {
color:#eceff3;
}

.pf_text h4 {
color:#eceff3;
margin-bottom: 0;
font-size: 14px;
font-weight: 600;
}

.pf_title{
	display:block;
}

.project-info{
color:#ccc;
margin-left:20px;
}

.de_light .project-info{
color:#606060;
}

.project-info h2{
margin-top: 0;
font-size:18px;
letter-spacing:3px;
text-transform:uppercase;
}

.project-info h4{
font-weight:bold;
}

.project-info .details{
padding:20px;
border:solid 1px rgba(255,255,255,.2);
background:rgba(0,0,0,.5);
margin-bottom:30px;
}

.de_light .project-info .details{
background:#eee;
border-color:#ddd;
}

.project-infoproject-info span{
display:block;
}

.project-info span.title{
display:inline-block;
min-width:80px;
}

.project-info span.val{
margin-bottom:15px;
font-weight:bold;
}

.project-view{
padding:120px 0 80px 0;
}

.project-images img{
width:100%;
}

.picframe {
	position: relative;
	overflow: hidden;
}
.picframe img{
	width:100%;
}

.picframe:hover .overlay-v{
	transform: scale(1.2);
	opacity:0;
}

.picframe:hover img{
	transform: scale(1.3);
}

.s2 .overlay-v,
.s2 .overlay-v:hover{
	background: rgba(40,40,40,.3);
}
.s2 .overlay-v .project-name{
	font-size:16px;
	letter-spacing:0;
	text-transform:none;
	color:#fff;
}

#btn-close-x{
	width:100px;
	height:100px;
	display:block;
	/* background:url(../images/close.png) center no-repeat; */
	margin:0 auto;
	margin-bottom:80px;
	cursor:pointer;
	padding:20px;
}

#btn-close-x{
	/* background:url(../images/close-2.png) center no-repeat; */
}


.project-name{
font-size:12px;
font-weight:bold;
display:block;
}

span.overlay {
text-align: center;
display: block;
width: 100px;
background: #111;
position: absolute;
z-index:1;
}

.de_light span.overlay {
background: #fff;
}

.info-details .info-text{
margin-bottom:20px;
}

.info-details .title{
display:block;
font-weight:bold;
}


span.icon{
cursor:pointer;
}



.overlay .info-area {
display: block;
margin-top: 10%;
}

.overlay .sb-icons {
display: block;
margin-top: 200px;
}

.de-gallery .overlay{
text-align:center;
}

.overlay  .pf_text{
display: inline-block;
}

.project-name {
    font-size: 15px;
    letter-spacing: 0px;
    text-transform: capitalize;
}

.de-gallery .overlay .icon {
display: inline-block;
margin-top: 200px;
text-align:center;
}

.de-gallery .overlay .icon i{
text-shadow:none;
color:#fff;
border:solid 1px rgba(255,255,255,.1);
font-size: 22px;
padding: 20px;
width: 60px;
height: 60px;
text-align: center;
margin-left:10px;
}

.de-gallery .overlay .icon i.icon-info{
margin-left:0px;
}

.de-gallery .overlay .icon i:hover{
color:#222;
background:#fff;
}

.de-gallery.hover-1 span.overlay-1 {
text-align: center;
display: block;
background:rgba(0,0,0,.7);
position: absolute;
width:100%;
height:100%;
z-index:2;
}
.de-gallery.hover-1 span.overlay-1:hover{
background:rgba(0,0,0,0);
}
.de-gallery.hover-1 span.overlay-1 .pf_text{
	display:block;
	bottom:10%;
	letter-spacing:4px;
	position:absolute;
	width:100%;
}
.de-gallery.hover-1 span.overlay-1:hover .pf_text{
	bottom:-20%;
	opacity:0;
}
.grid_gallery .item{
margin-bottom:30px;
}

a.img-icon-url, a.img-icon-zoom {
display: inline-block;
width: 36px;
height: 36px;
/* background: url(../images/icon-url.png) center no-repeat; */
margin: 0 auto;
margin: 0 2px 0 2px;
}

a.img-icon-zoom {
	/* background: url(../images/icon-zoom.png) center no-repeat; */
}

.pf_full_width .item{
width:24.9%;
float:left;
}

.pf_full_width.pf_3_cols .item{
width:33.3%;
}

.pf_full_width.pf_2_cols .item{
width:49.9%;
}

.pf_full_width img{
float:left;
width:100%;
}

	/* ---- grid ---- */

.grid {
  width:100%;
}

	 

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .element-item ---- */

/* 5 columns, percentage width */

.grid-sizer {
  width: 5%;
}

.grid-item {
  float: left;
  border:none;
  overflow:hidden;
  margin:0;
  padding:0;
}

.large-width { width: 50%; }



@media only screen and (max-width : 768px) {
  .w1, .w2, .w3, .w4, .w5 {width:100%}
}


/* ================================================== */
/* widget */
/* ================================================== */
#sidebar{
padding-left:20px;
}
 
.widget {
margin-bottom: 60px;
padding-bottom:60px;
border-bottom:solid 1px #333;
}

.de_light .widget{
border-bottom:solid 1px #ddd;
}

.widget h4 {
text-transform:uppercase;
padding-bottom: 20px;
font-size: 16px;
letter-spacing: normal;
letter-spacing:1px;
}

.widget ul {
list-style: none;
margin: 0;
padding:0;
}

.widget  .small-border{
height:2px;
width:30px;
background:#fff;
margin:10px 0 20px 0;
display:block;
}

.widget_category li {
padding: 8px 20px 8px 20px;
margin-bottom: 8px;
background:#111;
text-transform:uppercase;
}

.de_light .widget_category li {
background:#eee;
}

.widget_category li a {
color:#606060;
}

.widget_recent_post li {
	padding-left: 20px;
	/* background: url(../images/list-arrow.png) left 2px no-repeat; */
	border-bottom: solid 1px #222;
	padding-bottom: 7px;
	margin-bottom: 5px;
}

.widget .comments li {
	padding-left: 20px;
	/* background: url(../images/list-arrow.png) left 2px no-repeat; */
	border-bottom: solid 1px #eee;
	padding-bottom: 7px;
	margin-bottom: 5px;
}

.widget_tags li {
text-shadow:none;
display: inline-block;
margin-right: 2px;
margin-bottom: 13px;
}

.widget_tags li a {
font-size:12px;
text-decoration: none;
margin-bottom: 5px;
border-radius: 1px;
-moz-border-radius: 1px;
-webkit-border-radius: 1px;
border:solid 1px rgba(255,255,255,.1);
color: #888;
padding: 5px 8px 5px 8px;
}

.de_light .widget_tags li a {
color:#606060;
border:solid 1px #ddd;
}

.widget_tags li a:hover {
color:#555;
border-color: #555;
}

.de_light .widget_tags li a:hover {
color:#111;
border-color: #111;
}


.widget_top_rated_product ul{
padding:0;
}

.widget_top_rated_product li {
min-height:80px;
}

.widget_top_rated_product li .text{
padding-top:5px;
padding-left:75px;
}

.widget_top_rated_product li img{
position:absolute;
width:60px;
height:60px;
}

.widget_tags li a{
border-color:#555;
}

.widget_tags li a:hover {
color:#eceff3;
border-color:#eceff3;
}

footer .widget{
border:none !important;
margin-bottom:0;
}

.widget_tags ul{
margin-top:30px;
}

footer .widget.widget_tags li a{
border-color:#222;
}

footer .widget_recent_post li {
padding: 2px 0 4px 0;
margin:0;
background: none;
border-bottom-color:#222;
}

footer .widget{
border:none !important;
margin-bottom:0;
}

.widget_tags ul{
margin-top:30px;
}

footer .widget.widget_tags li a{
border-color:#222;
}

footer.light .widget_recent_post li {
border-bottom-color:#eee;
}

footer .widget h3{
margin-bottom:20px;
}

footer.style-2{
	padding:80px 0 60px 0;
}




/* ================================================== */
/* misc */
/* ================================================== */
a{
color:#fff;
text-decoration:none;
outline:0;
}

a:hover{
color:#fff;
}

a:hover, a:active, a:focus, img{
	outline:0;
}

.de_light a, .de_light a:hover{
color:#606060;
}

.wm{
	width:100%;
	position:relative;
	font-size:200px;
	text-align:center;
	font-weight:bold;
	top:33px;
	-webkit-user-select:none;-moz-user-select:-moz-none;
	-ms-user-select:none;user-select:none
}

.de_light .wm{
	color:rgba(0,0,0,.05);
}

/* video button begin */

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #eb3349; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ba1f24;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #eb3349;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#eb3349, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/* video-button close */

.clearfix{width:100%;}

.strong{font-weight:bold;}

.fontsize24{font-size:24px !important;}
.fontsize32{font-size:32px !important;}
.fontsize36{font-size:36px !important;}
.fontsize40{font-size:40px !important;}
.fontsize48{font-size:48px !important;}

.padding5{padding:5px !important;}
.padding10{padding:10px !important;}
.padding20{padding:20px !important;}
.padding30{padding:30px !important;}
.padding40{padding:23px !important;}
/* .padding40{padding:50px !important;} */
.padding50{padding:60px !important;}
.padding60{padding:70px !important;}


#particles-js{
	top:0;
	width:100%;
	height:100%;
	position:absolute;
	z-index:0;
}

.de_light a:hover{
color:#555;
}

#services-list{
padding:0;
margin:0;
}

#services-list li a{
color:#fff;
}

.de_light #services-list li a{
color:#999;
}

#services-list li{
font-weight:bold;
list-style:none;
margin:0;
background:rgba(0,0,0,.3);
margin-bottom:10px;
}

.de_light #services-list li{
background:#ddd;

}

#services-list li a{
display:block;
padding:20px;
text-decoration:none;
}

#services-list li a:hover{
color:#111;
}

.pic-services img{
margin-bottom:30px;
}

#services-list li.active a{
color:#111;
}

#services-list.s1 li{
	background:#303030;	
}
#services-list.s1 li a{
	color:#fff;
}

.project-images img{
margin-bottom:60px;
}

.teaser-text{
font-family:"Dosis";
font-size:14px;
color:rgba(255,255,255,.5);
line-height:normal;
font-weight:300;
text-transform:uppercase;
letter-spacing:6px;
}

.teaser-text-2{
font-size:16px;
}

#navigation{
position:fixed;
width:100%;
height:60px;
top:0;
right:20px;
text-align:center;
margin-bottom:60px;
z-index:1000;
}

.mfp-close{
color:#fff !important;
position:absolute;
width:100%;
font-size:48px;
margin-top:40px;
z-index:1000;
}

.de_light .mfp-close{
color:rgba(0,0,0,.5) !important;
}

.de_light .mfp-close:hover{
color:rgba(0,0,0,1) !important;
}

.nav-prev:before,.nav-next:before, .nav-exit:before{
	font-family:"FontAwesome";
	content:'\f177';
	color:#fff;
	padding:20px;
	font-size:24px;
	cursor:pointer;
}

.nav-exit:before{
width:100%;
display:block;
}

.nav-next:before{
	content:'\f178';
	width:100%;
	background:#555;
}

.nav-exit:before{
	content:'\f00d';
}

.de_light .nav-exit:before{
color:#222;
}

.container-4 .de-team-list{
width:24.9%;
float:left;
position:relative;
}

.container-3 .de-team-list{
width:33.3%;
float:left;
position:relative;
}

.no-margin{
	margin:0 !important;
}

.no-bottom{
margin-bottom:0;
padding-bottom:0;
}

p{
	margin-bottom:20px;
}


.bg-grey{
	background:#f5f5f5;
}

.no-bottom .col-md-12 {
}

.no-bottom {
padding-bottom: 0 !important;
}
.no-top {
padding-top: 0 !important;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}


#filters a{
	background:none;
}

.side-bg{
	position:relative;
	overflow:hidden;
}

.side-bg .image-container {
    height:100%;
    overflow:hidden;
    padding:0;
    position:absolute;
	background-size:cover !important;
}

.side-bg .background-image {
    background-position:50% 50%!important;
    background-size:cover!important;
    width:100%;
    height:100%;
    left:0;
    position:absolute;
    z-index:0
}

.image-slider{
	cursor:move;
}

.inner-padding{
	padding:90px 0 90px 0;
}

.no-padding,section.no-padding{
padding:0;
}

.float-left {
float: left;
}

#loader-area{
display:none;
background:#fff;
}

#loader-area{
display:none;
background:#111;
}

.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 99999;
	/* background: url('../images/page-loader.gif') 50% 50% no-repeat rgb(249,249,249); */
}

.page-overlay {
	display:none;
	width: 120px;
	height: 120px;
	/* background:url(../images/page-loader.gif) center no-repeat #fff; */
	border-radius:10px;
	position: fixed;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index:10000;
	margin: auto;
}

.teaser{
font-family:"Open Sans",Arial;
font-size:15px;
font-weight:300;
}

.form-control:focus{
box-shadow:none;
-moz-box-shadow:none;
-webkit-box-shadow:none;
}

.owl-arrow{
	position:absolute;
	z-index:100;
	background:#fff;
	margin-top:48%;
	display:none;
	cursor:pointer;
}
.owl-arrow span{
	display:block;
	background:#555;
	position:absolute;
	
}
.owl-arrow .prev{
	left:0;
}
.owl-arrow .next{
	right:0;
}

.owl-arrow .prev:before, .owl-arrow .next:before{
	font-family:"FontAwesome";
	content:'\f177';
	color:#fff;
	padding:20px;
	font-size:32px;
	display:block;
}
.owl-arrow .next:before{
	content:'\f178';
}

.owl-theme .owl-controls .owl-page span{
width:5px !important;
height:5px !important;
}

.owl-pagination{
	margin-top:0px;
}

.owl-custom-nav{
	position:absolute;
	z-index:1000;
	width:100%;
}
.owl-custom-nav .btn-next{
position:absolute;
right:0;
}
.owl-custom-nav .btn-prev{
position:absolute;
left:0;
}
.owl-custom-nav .btn-next:before{
font-family:"FontAwesome";
content:"\f105";
color:#111;
background:#333;
padding:5px 12px 5px 12px;
font-size:20px;
}
.owl-custom-nav .btn-prev:before{
font-family:"FontAwesome";
content:"\f104";
color:#111;
background:#333;
padding:5px 12px 5px 12px;
font-size:20px;
}

.owl-custom-nav a{
	text-decoration:none;
}

.owl-custom-nav .btn-prev:hover:before,
.owl-custom-nav .btn-next:hover:before{
	cursor:pointer;
	background:#111;
	color:#fff;
}

.owl-theme .owl-controls .owl-buttons div{
	background:#ffffff;
	opacity:1;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	padding-top:0px;
	height:32px;
	margin-top:20px;
	color:#111111;
	text-transform:uppercase;
	letter-spacing:5px;
}
.owl-theme .owl-controls .owl-buttons div:hover{
	opacity:.8 !important;
}

.owl-theme .owl-controls .owl-buttons .owl-prev:before{
font-family:"FontAwesome";
content:"\f104";
color:#111;
padding:5px 12px 5px 12px;
font-size:24px;
position:relative;
top:3px;
left:-10px;
}

.owl-theme .owl-controls .owl-buttons .owl-next:after{
font-family:"FontAwesome";
content:"\f105";
color:#111;
padding:5px 12px 5px 12px;
font-size:24px;
position:relative;
top:3px;
right:-15px;
}

/* new owl */

.owl-theme .owl-nav.disabled + .owl-dots {
	margin-top:20px;
}

.owl-dots button{
	background:none;
	border:none;
	outline:none;
	padding:0;
}
.owl-theme .owl-dots .owl-dot span{
	width:4px;
	height:4px;
}

.owl-theme .owl-dots .owl-dot:hover span{
	background:#fff;
}

/* new owl close */

.text-slider{
	line-height:60px;
	color:#fff;
	font-family:"Dosis";
	font-size:60px;
	text-transform:uppercase;
	font-weight:300;
	display:inline-block;
	letter-spacing:25px;
}

.text-slider.big-text{
	font-size:120px;
	line-height:120px;
	font-weight:bold;
}

.border-deco .item{
	display:inline-block;
}

.text-slider.dark{
	color:#222;
}



.item i{
	font-size:60px;
	margin-top:5px;
}

.text-slider.big-text i{
	font-size:90px;
	margin-top:15px;
}


.red{color:#ff0000 !important;}
.teal{color:#009999 !important;}
.purple{color:#663366 !important;}
.green{color:#009900 !important;}


.wow{
	/* visibility: hidden; */
	visibility: visible;
}


#back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
	outline:none;
}
#back-to-top:hover {
    background: #111;
}
#back-to-top.show {
    opacity: 1;
}
#back-to-top:before{
	font-family:"FontAwesome";
	font-size:22px;
	content:"\f106";
	color:#222;
	position:relative;
	margin:5px;
}
#back-to-top:hover:before{
	color:#fff;
}

/* mouse scroll icon begin */
.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 30px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 1px solid #fff;
  -webkit-animation: intro 1s;
  animation: intro 1s;
}
.scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: finger 1s infinite;
  animation: finger 1s infinite;
}
@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
/* mouse scroll icon close */

.bgimage{
background-size:cover;
}

.no-bg{
	background:none !important;
}

#ifrm,#cfs_top_div{
	display:none;
}

.info-box i{
	margin-top:5px;
	font-size:40px;
	position:absolute;
	}


.info-box .info-box_text{
	padding-left:60px;
}

.info-box .info-box_title{
	font-weight:700;
	text-transform:uppercase;
	line-height:16px;
	color:#fff;
	margin-top:8px;
}

.info-box.s2 .info-box_title{
	font-size:11px;
	letter-spacing:1px;
	margin-top:0px;
}

.header-light .info-box .info-box_text,
.header-light .info-box i{
	color:#333;
}

.text-right .info-box{
	position:relative;
	right:60px;
}

.text-right .info-box i{
	right:-60px;
}

.text-right .info-box .info-box_text{
	padding:0px;
}


.header-custom .info-box i{
	margin-top:-3px;
}

#info-box{
	height:90px;
	position:absolute;
	z-index:500;
	width:100%; 
	background:rgba(0,0,0,.3);
}

/* ===== demo switcher ===== */

#switcher{
	color:#333;
	padding:10px;
	padding-bottom:20px;
	background:#fff;
	font-size:12px;
	position:fixed;
	z-index:10000;
	top:150px;
	left:0;
	padding-left:20px;
	font-family:"Open Sans", Arial, Helvetica, sans-serif;
	font-size:12px;
	margin-left:-120px;
	border-radius: 0 0 2px 0;
	-moz-border-radius: 0 0 2px 0;
	-webkit-border-radius: 0 0 2px 0;
	width:120px;
}
#switcher .clearfix{
	margin-bottom:10px;
}
#switcher span{
font-size:12px;
}
.custom-show:after,.custom-close:after{
	position:absolute;
	margin-left:100px;
	margin-top:-10px;
	display:inline-block;
	width:42px;
	height:42px;
	padding:10px;
	padding-right:15px;
	color:#000;
	border-radius: 0 2px 2px 0;
	-moz-border-radius: 0 2px 2px 0;
	-webkit-border-radius: 0 2px 2px 0;
	cursor:pointer;
	content:"\f013";
	font-family:"FontAwesome";
	font-size:24px;
	font-weight:normal;
}
#de-color {
	padding:0;
	margin:0;
}
#de-color li{
	list-style:none;
	display:inline-block;
	float:left;
	width:38px;
	height:25px;
	margin:0 5px 5px 0;
	background:#0C9;
	border-radius:2px;
	-moz-border-radius:2px;
	-webkit-border-radius:2px;
	cursor:pointer;
}
#de-color li.bg1{background:#33C9BD;}
#de-color li.bg2{background:#0EC3F7;}
#de-color li.bg3{background:#469159;}
#de-color li.bg4{background:#999999;}
#de-color li.bg5{background:#F86E4E;}
#de-color li.bg6{background:#FE4365;}
#de-color li.bg7{background:#C762CB;}
#de-color li.bg8{background:#ED4C4D;}
#de-color li.bg9{background:#FBB829;}
#de-color li.bg10{background:#8BAD01;}
#de-color li:hover{
opacity:.7;
}
#switcher select{
	width:74px !important;
	font-size:12px;
	height:25px;
	border:none;
	background:#eee;
	font-weight:600;
	 -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	/* background:url(../demo/arrow-down.png) right no-repeat #eee; */
	padding-left:6px;
	padding-right:22px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
}
#switcher select:hover{
	background-color:#ddd;
}
#switcher select,
#de-color li
{
-o-transition:.5s;
-ms-transition:.5s;
-moz-transition:.5s;
-webkit-transition:.5s;
transition:.5s;
 outline: none;
}

/* ================================================== */
/* quick-search */
/* ================================================== */
.quick-search {
display: inline-block;
float: right;
margin-left: 10px;
padding: 10px 0 0 0;
}

.quick-search input {
width: 120px;
border-radius: 40px;
} 

#search{
float:left;
width:70%;
margin-bottom:0;
border:solid 1px #333333;
}

#search.form-control{
background:none;
}

#btn-search{
border:none;
padding:10px;
background:#222;
float:left;
width:30%;
height:46px;
}

#btn-search:before{
	font-family:FontAwesome;
	content: "\f002";
	font-size:24px;
	color:#111;
}

#btn-search:hover{
opacity:.8;
}
.center-xy{
position:absolute;
z-index:5;
width:100%;
height:100%;
text-align:center;
}

.center-xy i.btn-action{
	font-size:24px;
	border:solid 2px rgba(255,255,255,.3);
	padding:16px;
	width:60px;
	height:60px;
	border-radius:60px;
	text-align:center;
	color:#fff;
}

.center-xy i.btn-action:hover{
border-color:rgba(255,255,255,0);
background:rgba(255,255,255,.3);
}


.center-xy i.btn-action.btn-play{
padding-left:20px;
}

.bg-overlay{
position:absolute;
width:100%;
height:100%;
z-index:1;
}
.pic-hover{
width:100%;
position:relative;
overflow:hidden;
}
.pic-hover{
width:100%;
position:relative;
overflow:hidden;
}

i.btn-action-hide{
opacity:0;
}

.pic-hover:hover .bg-overlay{
background:rgba(0,0,0,.5);
}

.pic-hover:hover i.btn-action-hide{
opacity:1;
}

.pic-hover.hover-scale:hover img{
 -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* ================================================== */
/* review */
/* ================================================== */
.de_review {
text-align:center;
min-height:260px;
}

.de_review .de_nav {
overflow: hidden;
padding-left: 0;
margin: 0;
padding: 0;
font-weight:bold;
text-align:center;
}

.de_review .de_nav li {
list-style: none;
background: #EEE;
margin: 0 10px 0 10px;
margin-bottom:20px;
}

.de_review .de_nav li{
display:inline-block;
}

.de_review .de_nav li img{
width:100px;
height:auto;
}

.de_review .de_nav li span {
padding: 8px 16px 8px 16px;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
border-left: 1px solid #ddd;
border-right: 1px solid #ddd;
display: block;
background: #f5f5f5;
text-decoration: none;
color: #646464;
font-size: 13px;
cursor: pointer;
}

.de_review blockquote{
border:none;
font-family:"Roboto Slab", Georgia, "Times New Roman", Times, serif;
}

.de_review .de_nav li span {
border: 1px solid #404040;
background:#222;
}

.de_review .de_nav li:first-child span {
border-left: 1px solid #ddd;
}

.de_review .de_nav li:first-child span {
border-left: 1px solid #404040;
}

.de_review .de_review_content {
border: 1px solid #ddd;
padding: 15px 20px 20px 20px;
margin-top: -1px;
background: #fff;
}

.de_review .de_review_content {
border: 1px solid #404040;
background: #27282b;
}

.de_review .de_nav li.active span {
background: #FFF;
border-bottom: 1px solid #fff;
color: #222;
margin-bottom: -3px;
}


.separator {
  line-height: 0.5;
  text-align: center;
  margin:30px 0 30px 0;
}
.separator span {
  display: inline-block;
  position: relative;
 
}
.separator span:before,
.separator span:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255,255,255,.1);
  top: 5px;
  width: 300px;
}
.separator span:before {
  right: 100%;
  margin-right: 15px;
}
.separator span:after {
  left: 100%;
  margin-left: 15px;
}

.separator span  i{
font-size:6px;
padding:0;
margin:0;
float:none;
}

.de_light .separator span:before,
.de_light .separator span:after {
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.text-light .separator span:before,
.text-light .separator span:after {
  border-bottom: 1px solid rgba(255,255,255,.1);
}


/* css attributes */

/* margin top */
.mt0{margin-top:0;}.mt10{margin-top:10px;}.mt20{margin-top:20px;}.mt30{margin-top:30px;}.mt40{margin-top:40px;}.mt50{margin-top:50px;}.mt60{margin-top:60px;}.mt70{margin-top:70px;}.mt80{margin-top:80px;}.mt90{margin-top:90px;}.mt100{margin-top:100px;}

/* margin bottom */
.mb0{margin-bottom:0;}.mb10{margin-bottom:10px;}.mb20{margin-bottom:20px;}.mb30{margin-bottom:30px;}.mb40{margin-bottom:40px;}.mb50{margin-bottom:50px;}.mb60{margin-bottom:60px;}.mb70{margin-bottom:70px;}.mb80{margin-bottom:80px;}.mb90{margin-bottom:90px;}.mb100{margin-bottom:100px;}

.pt0{padding-top:0;} 
.pt10{padding-top:10px;} 
.pt20{padding-top:20px;} 
.pt30{padding-top:30px;} 
.pt40{padding-top:40px;} 
.pt50{padding-top:50px;} 
.pt60{padding-top:60px;} 
.pt80{padding-top:80px;}

.pb0{padding-bottom:0;} 
.pb10{padding-bottom:10px;} 
.pb20{padding-bottom:20px;} 
.pb30{padding-bottom:30px;} 
.pb40{padding-bottom:40px;} 
.pb50{padding-bottom:50px;} 
.pb60{padding-bottom:60px;} 
.pb70{padding-bottom:40px;} 
.pb80{padding-bottom:70px;}


/* margin top */
.mt-10{margin-top:-10px;}.mt-20{margin-top:-20px;}.mt-30{margin-top:-30px;}.mt-40{margin-top:-40px;}.mt-50{margin-top:-50px;}.mt-60{margin-top:-60px;}.mt-70{margin-top:-70px;}.mt-80{margin-top:-80px;}.mt-90{margin-top:-90px;}.mt-100{margin-top:-100px;}.mt-110{margin-top:-110px;}.mt-120{margin-top:-120px;}.mt-120{margin-top:-120px;}.mt-130{margin-top:-130px;}
	
.mr10{margin-right:10px;}.mr20{margin-right:20px;}.mr30{margin-right:30px;}
	
.absolute{position:absolute; z-index:1;}
.overflow-hidden{overflow:hidden !important;}
.width100{width:100%;}

.border1{
border-width:1px !important;
}

.display-table{
display:table;
}

.display-table [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
	margin:0;
	padding:0;

}

.text-middle, .display-table [class*="col-"].text-middle {
	vertical-align: middle;
}

.overlay30{background:rgba(0,0,0,.3); height:100%;}
.overlay40{background:rgba(0,0,0,.4); height:100%;}
.overlay50{background:rgba(0,0,0,.5); height:100%;}
.overlay60{background:rgba(0,0,0,.6); height:100%;}
.overlay70{background:rgba(0,0,0,.7); height:100%;}
.overlay80{background:rgba(0,0,0,.8); height:100%;}
.overlay90{background:rgba(0,0,0,.9); height:100%;}


.shadow-soft{
	-webkit-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.4);
	box-shadow: 2px 2px 20px 0px rgba(0,0,0,0.4);
}

.overflow-hidden{
	overflow:hidden;
}

.height-auto{
	height:auto;
}

/* ================================================== */
/* R E S P O N S I V E */
/* ================================================== */
	@media(min-width:980px) and (max-width: 1199px) {
	
	header #mainmenu a{
	padding-left:5px;
	padding-right:5px;
	}
	
	#mainmenu li a:after{
	margin-left:15px;
	}
	
	}

	/* -------------------------------------------------- 
	design for 768px
	/* ================================================== */
	@media (min-width: 768px) and (max-width: 979px) {
	.slider_text {
	padding-top: 0px;
	}
	
	}
	
	
	@media only screen and (max-width: 992px) {
		
	#menu-btn{
	display:block;
	position:relative;
	float:right;
	}
	
	
	header{
	display:none;
	}
	
	header .info{
	display:none !important;
	}
	
	header.header-bg{
	background:rgba(0,0,0,1);
	}
	
	header,header.fixed {
	display:block;
	position:inherit;
	margin:0;
	padding:0;
	margin-top:0;	
	background:#000000;
	padding:15px 0 15px 0;
	}
	
	#de-sidebar{
		position:absolute !important;
		width:100%;
		right:1px;
	}
	
	
	header.side-header,
	.de_light header.side-header{
	position:absolute !important;
	display:block;
	height:60px;
	top:0;
	background:#000;
	}
	
	header.side-header #mainmenu{
	position:absolute;
	background:#000;
	margin:0;
	top:60px;
	left:0;
	width:100%;
	}
	
	header.side-header #logo .logo{
	display:none;
	}
	
	header.side-header #logo .logo-2{
	display:inline-block;
	position:absolute;
	height:30px;
	top:15px;
	left:40px;
	}
	
	header.side-header #menu-btn{
	position:absolute;
	top:8px;
	right:30px;
	height:0;
	z-index:2000;
	height:35px;
	}
	
	
	header.side-header #mainmenu li{
	display:block;
	}
	
	header.side-header #mainmenu a,
	.de_light.de-navbar-left header #mainmenu > li > a{
	display:block;
	text-align:center;
	color:#fff;
	}
	
	.de_light.de-navbar-left header #mainmenu > li{
	border-bottom:solid 1px rgba(255,255,255,.1);
	}
	
	header.side-header #mainmenu a:hover{
	background:none !important;
	}
	
	header.side-header #mainmenu a:hover{
	background:none !important;
	}
	
	header.side-header .social-icons-2{
	display:none;
	}
	
	header.autoshow{
	top:0 !important;
	}
	
	header.autoshow #mainmenu li a{
	background:none !important;
	}
	
	header.header-mobile-sticky{
	position:fixed;
	}
	
	header.de_header_2{
	height:auto;
	padding-bottom:0;
	}
	
	#subheader,.de-navbar-left  #subheader{
		padding:0;
		margin:0;
	}
	#subheader h1{
		margin:60px 0 30px 0;
	}
	#subheader span{
	display:none;
	}
	#subheader .crumb{
	display:none;
	}
	
	.de-navbar-left  #subheader{
	padding-top:70px;
	}
	
	.slider_text h1 {
	font-size: 32px;
	}
	
	.slider_text .description {
	display: none;
	}
	div#logo{
	margin-top:-15px;
	}
	#logo, #logo .inner {
	 vertical-align:middle;
	 height:auto;
	}
	
	#domain-check .text, #domain-check .button {
	width: 100%;
	}
	
	.container-4 .de-team-list{
	width:49.9%;
	float:left;
	position:relative;
	}
	
	#testimonial-full blockquote {
	padding: 20px;
	}
	
	#revolution-slider{
	}
	
	nav{
	width:100%;
	}
	
	#mainmenu{
	display:none;
	float:none;
	z-index:200;
	width:100%;
	margin:0;
	padding:0;
	}
	
	#mainmenu a{
	text-align:left;
	padding-left:0;
	padding-top:10px !important;
	padding-bottom:10px !important;
	}
	
	#mainmenu li li a,
	#mainmenu li li li a{
	padding-left:0;
	}
	
	#mainmenu a:hover{
	background:#111 !important;
	}
	
	
	#mainmenu li ul{
	display:block;
	position:inherit;
	margin:0;
	width:100%;
	}
	
	#mainmenu li{
	border-bottom:solid 1px #333;
	margin:0;
	width:100%;
	display:block;
	letter-spacing:3px;
	}
	
	#mainmenu li a:after{
	display:none;
	}
	
	#mainmenu li:last-child{
	margin-bottom:30px;
	}
	
	#mainmenu li ul{
	border-top:solid 1px #333;
	top:auto;
	}
	
	#mainmenu li ul a{
	width:100%;
	background:none;
	border:none;
	}
	
	#mainmenu li ul li{
	border:none;
	padding-left: 40px !important;
	}
	
	#mainmenu li ul li a{
	display:block;
	}
	
	#mainmenu li ul li:last-child{
	border-bottom:none;
	margin:0;
	}
	
	#mainmenu li ul li:last-child a{
	border-bottom:none;
	}
	#mainmenu li a{
	color:#eceff3;
	}
	
	#mainmenu  li li a{
	font-size:10px;
	}
	
	#mainmenu  li li a:hover{
	color:#fff;
	}
	
	
	header.header-mobile #mainmenu{
	display:none;
	float:none;
	z-index:200;
	width:100%;
	margin:0;
	padding:0;
	}
	
	header.header-mobile.header-custom #mainmenu{
	padding:0 40px 0 40px;
	}
	
	header.header-mobile #mainmenu a{
	text-align:left;
	padding-top:10px !important;
	padding-bottom:10px !important;
	}
	
	header.header-mobile #mainmenu a:hover{
	background:none !important;
	}
	
	
	header.header-mobile #mainmenu li ul{
	display:block;
	position:inherit;
	margin:0;
	width:100%;
	}
	
	header.header-mobile #mainmenu li{
	border-bottom:solid 1px #333;
	margin:0;
	width:100%;
	display:block;
	letter-spacing:3px;
	}
	
	header.header-mobile.header-light #mainmenu li{
	border-bottom-color:#eee;
	}
	
	header.header-mobile #mainmenu li a:after{
	display:none;
	}
	
	header.header-mobile #mainmenu li:last-child{
	margin-bottom:30px;
	}
	
	header.header-mobile #mainmenu li ul{
	border-top:none;
	top:auto;
	}
	
	header.header-mobile #mainmenu li ul a{
	width:100%;
	background:none;
	border:none;
	}
	
	header.header-mobile #mainmenu li ul li{
	border:none;
	padding-left: 40px !important;
	}
	
	header.header-mobile #mainmenu li ul li:first-child{
	border-top:solid 1px #333;
	}
	header.header-mobile.header-light #mainmenu li ul li:first-child{
	border-top-color:#eee;
	}
	
	header.header-mobile #mainmenu li ul li a{
	display:block;
	border-bottom:solid 1px #333;
	}
	header.header-mobile.header-light #mainmenu li ul li a{
	border-bottom-color:#eee;
	}
	
	header.header-mobile #mainmenu li ul li:last-child{
	border-bottom:none;
	margin:0;
	}
	
	header.header-mobile #mainmenu li ul li:last-child a{
	border-bottom:none;
	}
	
	header.header-mobile #mainmenu li a{
	color:#eceff3;
	}
	
	header.header-mobile.header-light #mainmenu li a{
	color:#404040;
	}
	
	header.header-mobile #mainmenu  li li a{
	font-size:10px;
	}
	
	header.header-mobile #mainmenu  li li a:hover{
	color:#fff;
	}
	
	header.header-mobile #mainmenu li ul{
	height:0;
	overflow:hidden;
	position:relative;
	left:0;
	}
	
	
	header.header-mobile #mainmenu li ul li ul li:last-child{
	border-bottom:solid 1px #333;	
	}
	header.header-mobile.header-light #mainmenu li ul li ul li:last-child{
	border-bottom-color: #eee;	
	}
	
	header.header-mobile #mainmenu li ul li ul{
	margin-bottom:10px;
	}
	
	header.header-mobile #mainmenu > li > span{
		width:36px;
		height:36px;
		/* background:url(../images/ui/arrow-down.png) center no-repeat; */
		position:absolute;
		right:0;
		margin-top:10px;
		z-index:1000;
		cursor:pointer;
	}
	header.header-mobile.header-light #mainmenu > li > span{
		/* background:url(../images/ui/arrow-down-invert.png) center no-repeat; */
	}
	
	header.header-mobile #mainmenu li span.active{
		/* background:url(../images/ui/arrow-up.png) center no-repeat; */
	}
	header.header-mobile.header-light #mainmenu li span.active{
		/* background:url(../images/ui/arrow-up-invert.png) center no-repeat; */
	}
	
	header.header-mobile #mainmenu li > ul > li > span{
		width:36px;
		height:36px;
		/* background:url(../images/ui/arrow-down.png) center no-repeat; */
		position:absolute;
		right:0;
		margin-top:-45px;
		z-index:1000;
		cursor:pointer;
	}
	header.header-mobile.header-light #mainmenu li > ul > li > span{
		background:url(../images/ui/arrow-down-invert.png) center no-repeat;
	}
	
	header.header-mobile #mainmenu > li > ul > li > span.active{
		/* background:url(../images/ui/arrow-up.png) center no-repeat; */
	}
	header.header-mobile.header-light #mainmenu > li > ul > li > span.active{
		/* background:url(../images/ui/arrow-up-invert.png) center no-repeat; */
	}
	
	header.header-mobile #mainmenu li ul li a{
		border-bottom:solid 1px #333;
	}
	header.header-mobile.header-light #mainmenu li ul li a{
		border-bottom-color:#eee;
	}
	header.header-mobile #mainmenu li ul li:last-child > a{
		border-bottom:none !important;
	}
	
	header.header-mobile #mainmenu li ul.mega ul {
		height: auto;
	}
	
	#mainmenu li ul.mega{
	position:fixed;
	left:0;
	width:100%;
	margin-top:30px;
	display:block;
	padding:0;
	}
	#mainmenu li ul.mega > li{
		width:100%;
	}
	#mainmenu li ul.mega > li ul{
		position:static;
		visibility: visible;
		opacity: 1;
		left:0;
		float:none;		
		width:100%;
	}
	#mainmenu ul.mega > li ul li{
		margin:0;
		padding:0;
	}

	#mainmenu li ul.mega .menu-content{
		background:none;
		padding:30px;
	}
	
	header.header-mobile #mainmenu ul.mega li{
		border-bottom:solid 1px #333;
		padding-left:0 !important;
	}
	
	header.header-mobile #mainmenu ul.mega li.title{
		border-bottom:solid 1px #777 !important;
	}
	
	header.header-mobile #mainmenu ul.mega li:first-child{
		border:none;
	}
	
	.de_tab.tab_steps  .de_nav{
	text-align:center;
	}
	
	.de_tab.tab_steps .de_nav li{
	display:block;
	margin:0;
	padding:0;
	}
	
	.de_tab.tab_steps .de_nav li span{
	margin:0;
	margin-bottom:40px;
	display:inline-block;
	}
	
	.de_tab.tab_steps .de_nav li .v-border{
	border:none;
	}
	
	.container .col-md-3{ margin-bottom:30px; }
	.container .col-md-4{ margin-bottom:30px; }
	.container .col-md-5{ margin-bottom:30px; }
	.container .col-md-6{ margin-bottom:30px; }
	.container .col-md-7{ margin-bottom:30px; }
	.container .col-md-8{ margin-bottom:30px; }
	.container .col-md-9{ margin-bottom:30px; }
	.container .col-md-10{ margin-bottom:30px; }
	.container .col-md-11{ margin-bottom:30px; }
	.container .col-md-12{ margin-bottom:30px; }
	
	.hidden-phone{
		display:none;
	}

	.grid_gallery .item{
	width:100%;
	}
	
	.de-navbar-left #wrapper{
	overflow:hidden;
	width:100%;
	}
	
	.de-navbar-left .inner-padding{
	padding:30px;
	}
	
	.de-navbar-left header #mainmenu > li > a {
	padding-left:40px;
	}
	
	.side-bg .image-container{ 
	position:relative; 
	width:100%; 
	height:400px !important; 
	top:0;
	margin-bottom:60px;
	}
	
	.mobile-hide{
	display:none;
	}
	
	.display-table{
	display:block;
	}

	.display-table [class*="col-"] {
	float: none;
	display: block;
	vertical-align: top;
	}
	
	h2.hero{
	font-size:28px;
	letter-spacing:5px;
	}

	#filters li.pull-right{
	float:none !important;
	}
	
	.sm-hide{
	display:none;
	}
	
	#mainmenu li ul.mega .menu-content{
		padding:0;
		padding-left:40px;
	}
	
	.mega [class*="col-"] {
		margin-bottom:0;
	}
	
	.mega .spacer-single{
		height:0;
	}
	
	header.header-mobile #mainmenu li ul.mega li.title{
		border-top:solid 1px #333333;
		padding-top:15px;
	}
	
	header.header-mobile #mainmenu li ul.mega li ul li:last-child{
		border-bottom:none;
		margin-bottom:0;
	}
	
	header.header-mobile #mainmenu li ul.mega li ul{
		margin-bottom:0;
	}
	
	.sm-mt0{
		margin-top:0;
	}
	
	.sm-no-padding{
		padding:0;
	}
	
	}


	/* -------------------------------------------------- 
	custom for 320px & 480px 
	/* -------------------------------------------------- */
	@media only screen and (max-width: 767px) {
	.container{padding-left:30px; padding-right:30px;}
	.feature-box-image,.pricing-box{margin-bottom:30px;}
	
	.de_tab .de_nav li {
	display: block;
	width: 100%;
	}
	
	.pf_full_width .item{
	float:left;
	width:49.9% !important;
	}

	/*.item{
	font-size:48px;
	line-height:48px;
	}*/
	
	.item i{
	font-size:36px;
	}
	
	#de-sidebar{
	width:100%;
	display:block;
	position:relative;	
	}	
		
	.pricing-2-col .pricing-box, .pricing-3-col .pricing-box, .pricing-4-col .pricing-box, .pricing-5-col .pricing-box {
	width: 100%;
	}
	
	.container-4 .de-team-list{
	width:100%;
	float:left;
	position:relative;
	}
	
	.countdown-section {
	font-size: 12px;
	}
	.countdown-amount {
		font-size: 36px;
	}
	
	.de-navbar-left header #mainmenu > li > a {
	padding-left:40px;
	}
	
	.de-navbar-left .inner-padding{
	padding:0px;
	}
	
	h1.title-2{
	font-size:16px;
	letter-spacing:10px;
	line-height:2em;
	}
	
	
	header.de_header_2{
		height:auto;	
	}
	
	.sm-no-padding{
		margin-top:0;
	}
	
	.mt-sm-none{
		margin-top:0;
	}
	
	}
	
	
	/* -------------------------------------------------- 
	design for 480px
	/* -------------------------------------------------- */
	@media only screen and (min-width: 480px) and (max-width: 767px) {
	.container{padding-left:30px; padding-right:30px;}
	.feature-box-image,.pricing-box{margin-bottom:30px;}
	
	}
	
	
	/* -------------------------------------------------- 
	design for 320px
	/* -------------------------------------------------- */
	@media only screen and (max-width: 480px) {
	.pf_full_width .item{
	float:left;
	width:100% !important;
	}
	
	/*.item{
	font-size:32px;
	line-height:32px;
	}*/
	
	.item i{
	font-size:24px;
	}
	
	.grid-item .pf_title{
		display:none;
	}
	}
@media(max-width:375px){
	.box{
		flex-direction: column;
	}
	.padding40{padding:50px !important;}
    .padding40{padding:50px !important;}
}
@media(max-width:414px){
	.box{
		flex-direction: column;
	}
}.box-bg-1{	background: #383c44;    padding: 15px;  }

@media(min-width:800px){
	.box-bg-1{	 height: 350px; overflow: hidden; }
}

.cart-li i {
    font-size: 20px;
}
.cart-li span.badge {
	font-size: 14px;
}

.cart-table img.media-object{
    max-width: 126px;
}


#section-testimonial{
	background: url(../images/background/bg-3.jpg) top fixed;
}
.current-active{
	border: 1px solid #333;
    padding-left: 6px;
    padding-right: 6px;
}