#jpreOverlay {
	background-color: #000000;
	position:absolute;
	width:100%;
	height:100%;
}
#jpreSlide{
	font-size:22px;
	color:#ccc;
	text-align:center;
	width:50%;
	height:30%;
}
#jpreLoader{
	width:400px;
	height:2px;
	background:#111;
	border-radius:25px;
	-moz-border-radius:25px;
	-webkit-border-radius:25px;
}

#jpreBar {
	background:#222;
	border-radius:25px;
	-moz-border-radius:25px;
	-webkit-border-radius:25px;
}

#jprePercentage {
	color:#111;
	z-index:-1;
	font-family:Arial,Helvetica,sans-serif;
	text-align:center;
	margin-top:10px;
	font-size:96px;
	margin-top:-10px;
}

#jSplash{
	line-height:200%;
}

#jSplash section{
	padding:0;
	display:block;
	background:none !important;
}